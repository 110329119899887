import { Component, OnInit } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { SeniercitienComponent } from './seniercitien/seniercitien.component';

@Component({
  selector: 'ngx-fk-user',
  templateUrl: './fk-user.component.html',
  styleUrls: ['./fk-user.component.scss']
})
export class FkUserComponent implements OnInit {
  userList: any[];
  deActiveUserList: any[];
  copyUserList: any[];
  copyDeActiveUserList: any[];

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;


  user = 0;
  userId = 0;
  activeDeactiveBtn = false;

  pageSize = 10;
  pageNumber = 1;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };


  constructor(
    private toastrService: NbToastrService,
    private gpService: GpartnerService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.getFkUser();
    this.getDeActiveUserList();
    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		// this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'FlourPicker User');

		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getFkUser();
  }

  filter1(str: string) {
    str = str.trim();
    if (str !== '') {
      this.deActiveUserList = this.copyDeActiveUserList.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const pincode = String(item.mobile).toLocaleLowerCase();
        const mobile = String(item.pincode).toLocaleLowerCase();
        const area = String(item.areaName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) ||
          (pincode.includes(searchStr)) ||
          (mobile.includes(searchStr)) ||
          (area.includes(searchStr));
      });
    } else {
      this.deActiveUserList = this.copyDeActiveUserList;
    }
  }
  // activated user list
  getFkUser() {
    const url = `${environment.API_URL}/api/User/GetUserList?Pageno=${this.config.currentPage}&PageSize=${this.pageSize}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.userList = response.data.users;
          // this.getDeActiveUserList();
          this.copyUserList = response.data.users;
          // this.config.totalItems = this.userList.length;
          this.config.totalItems = response.data.count;
        }
      });
  }

  filter3(str: string) {
    str = str.trim();
    if (str !== ' ')  {
    const url = `${environment.API_URL}/api/User/GetUserListSearch?SearchStr=${str}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.userList = response.data.users;
          // this.getDeActiveUserList();
          this.copyUserList = response.data.users;
          // this.config.totalItems = response.data.count;
        } else {
          this.getFkUser();
        }
      });
    }
  }
  changeTag(event) {
    if (event.tabId === 'activeuser') {
      this.getFkUser();
    } else {
      this.getDeActiveUserList();
    }
  }
  // de-activated user list

  getDeActiveUserList() {
    const url = `${environment.API_URL}/api/User/GetDeActiveUserList?pageSize=0&pageNumber=0`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.deActiveUserList = response.data;
          this.copyDeActiveUserList = response.data;
          this.getFkUser();
        }
      });
  }


  openDeleteDialog(user: any) {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: '',
        data: user,
      },
    }).onClose.subscribe(() => {
      this.getFkUser();
      this.getDeActiveUserList();

    });
  }
  openSenerCitison(user: any) {
    this.dialogService.open(SeniercitienComponent, {
      context: {
        title: '',
        data: user,
      },
    }).onClose.subscribe(() => {
     // alert('close');
      this.getFkUser();
      // this.getDeActiveUserList();

    });
  }

  // To active user
  activeUser(userId) {
    this.activeDeactiveBtn = true;
    const url = `${environment.API_URL}/api/User/ActiveUser?UserId=${userId}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.getDeActiveUserList();
          this.getFkUser();
          this.activeDeactiveBtn = false;
          this.showToast('top-right', 'success', 'User Activeted Successfully');
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

}
