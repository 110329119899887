import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS,  } from '../../fp-menu-item';
import { MenuService } from '../../Shared/Services/menu.service';


@Component({
  selector: 'ngx-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  menu = MENU_ITEMS;

  constructor(
  private  menuService: MenuService
  ) { }

  ngOnInit() {
    this.menu =   this.menuService.setMenu();
  }

}
