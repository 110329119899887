import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { EmployeeService } from '../../Shared/Services/employee.service';

@Component({
  selector: 'ngx-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})


export class RatingComponent implements OnInit {

  RatingList: any[];
  CopyRatingList: any[];

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private employeeService: EmployeeService,
  ) { }

  ngOnInit() {
    this.getRatingList();
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.RatingList = this.CopyRatingList.filter(item => {
        const fName = String(item.orderNumber).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (fName.includes(searchStr) );
      });
    } else {
      this.RatingList = this.CopyRatingList;
    }
  }

  getRatingList() {
    const url = `${environment.API_URL}/api/RatingReview/RatingOfOrderForAdmin?PageNumber=0&PageSize=0`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.RatingList = response.data;
          this.CopyRatingList = response.data;
          this.config.totalItems = this.RatingList.length;
        }
      });
  }
}
