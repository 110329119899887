import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { StockService } from '../../Shared/Services/stock.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { IGrain, ISubGrain } from '../../models';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { StockoutDeleteDialogComponent } from './stockout-delete-dialog/stockout-delete-dialog.component';

@Component({
	selector: 'ngx-stockout',
	templateUrl: './stockout.component.html',
	styleUrls: ['./stockout.component.scss'],
})
export class StockoutComponent implements OnInit {
	stockOutFormArray: FormArray = this.fb.array([]);
	stockOutForm: FormGroup;
	submitted = false;
	empList = [];
	vendorList = [];
	grainList: IGrain[] = [];
	subGrainList: any[] = [];
	stockId = 0;
	stockList = [];
	copyStockList = [];

	isSaveBtnStatus = false;
	receivedStock = false;

	pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

	config = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0,
	};

	@ViewChild('stockoutitem', { static: false }) accordion;

	constructor(
		private fb: FormBuilder,
		private stockService: StockService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService
	) {
		this.stockOutForm = this.fb.group({
			vendorName: ['', [Validators.required]],
			challanNo: [''],
			date: ['', [Validators.required]],
			deliveryStatus: ['0', [Validators.required]],
			isAcceptByBP: [false],
			deliveredBy: [''],
		});
	}

	ngOnInit() {
		this.addstockOutForm();
		this.getVendorList();
		this.getGrainList();
		this.getStockList();
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		// this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Stock out');
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
	}

	get f() {
		return this.stockOutForm.controls;
	}
	selectVendorName() {
		this.submitted = true;
	}

	getVendorList() {
		const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.vendorList = response.data;
				}
			});
	}

	getStockItemList(itemFormArray: FormArray) {
		const temItemArray = [];
		itemFormArray.controls.forEach((form: FormGroup) => {
			if (form.valid) {
				const obj = {
					grainCategoryId: +form.value.CategoryId,
					quantity: +form.value.Qty,
					price: 0,
				};
				temItemArray.push(obj);
			}
		});
		return temItemArray;
	}

	getGrainList() {
		const url = `${environment.API_URL}/api/Grain/GetGrainList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.grainList = response.data;
				}
			});
	}

	getSubGrainstock(subGrainId, itemForm) {
		const url = `${
			environment.API_URL
			}/api/Stock/GetGrainCategoryStock?GrainCategoryId=${+subGrainId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					itemForm.controls.AvailableQty.setValue(response.data);
				}
			});
	}

	validateQty(event, itemForm) {
		const qty = +itemForm.controls.Qty.value;
		const availabeqty = +itemForm.controls.AvailableQty.value;

		if (qty > availabeqty) {
			itemForm.controls.AvailableQty.setValue(availabeqty);
			event.target.style.backgroundColor = '#ffccbb';
		} else {
			event.target.style.backgroundColor = '';
		}
	}

	getSubGrainList(GrainId, itemForm, subGrainId?: number) {
		this.subGrainList = [];
		const url = `${
			environment.API_URL
			}/api/GrainCategory/GetGrainCategoryByGrainId?grainId=${+GrainId}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<ISubGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					itemForm['subGrainList'] = response.data;
					itemForm.controls.CategoryId.setValue(subGrainId);
				}
			});
	}

	getSubGrainValue(subGrain, subGrainList: Array<any>, itemForm: FormGroup) {
		subGrainList.forEach((item) => {
			if (+subGrain === item.id) {
			}
		});
	}

	addstockOutForm() {
		this.stockOutFormArray.push(
			this.fb.group({
				GrainId: [0, [Validators.required]],
				CategoryId: [0, [Validators.required]],
				Qty: [0, [Validators.required]],
				AvailableQty: [0],
			})
		);
	}

	removeItem(index) {
		this.stockOutFormArray.controls.splice(index, 1);
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

	pageChange(pageNo) {
		this.config.currentPage = pageNo;
	}

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
			this.stockList = this.copyStockList.filter((item) => {
				const bill = String(item.billNumber).toLocaleLowerCase();
				const fName = String(item.gPartnerName).toLocaleLowerCase();
				const gpCode = String(item.gpCode).toLocaleLowerCase();
				const searchStr = String(str).toLowerCase();
				return (
					bill.includes(searchStr) ||
					fName.includes(searchStr) ||
					gpCode.includes(searchStr)
				);
			});
		} else {
			this.stockList = this.copyStockList;
		}
	}

	getStockList() {
		const url = `${environment.API_URL}/api/StockOut/GetOutStockList`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.stockList = response.data;
					this.copyStockList = response.data;
					this.config.totalItems = this.stockList.length;
				}
			});
	}

	openDeleteDialog(product) {
		this.accordion.close();
		this.dialogService
			.open(StockoutDeleteDialogComponent, {
				context: {
					title: '',
					data: product,
				},
			})
			.onClose.subscribe(() => {
				this.getStockList();
			});
	}

	clearForm() {
		this.stockId = 0;
		this.stockOutForm.reset();
		this.submitted = false;
		this.stockOutFormArray = this.fb.array([]);
		this.addstockOutForm();
		this.isSaveBtnStatus = false;
	}

	saveStock() {
		this.submitted = true;
		if (this.stockOutForm.valid) {
			this.isSaveBtnStatus = true;
			let url = '';
			if (this.stockOutFormArray.length > 0) {
				const data = {
					id: this.stockId,
					assignedById: 0,
					gpatnerId: +this.stockOutForm.value.vendorName,
					billNumber: this.stockOutForm.value.challanNo,
					// gPartnerName: this.stockOutForm.value.vendorName,
					gPartnerName: '',
					gPartnerCode: '',
					status: +this.stockOutForm.value.deliveryStatus,
					statusUpdateDate: this.getDate(this.stockOutForm.value.date),
					documentPath: '',
					deliverdBy: this.stockOutForm.value.deliveredBy,
					totalPrice: 0,
					tax: 0,
					stockDetail: this.getStockItemList(this.stockOutFormArray),
					isAcceptByBP: this.stockOutForm.value.isAcceptByBP,
				};

				if (this.stockId === 0) {
					url = `${environment.API_URL}/api/StockOut/AddGPartnerStock`;
				} else {
					url = `${environment.API_URL}/api/StockOut/UpdateGPartnerStock`;
				}
				this.stockService
					.modifyStock(url, data)
					.subscribe((response: ResponseModel<any>) => {
						if (response.status === HttpStatusCode.OK) {
							if (this.stockId === 0) {
								this.showToast('top-right', 'success', 'Added Successfully');
							} else {
								this.showToast('top-right', 'success', 'Updated Successfully');
							}
							this.stockId = 0;
							this.submitted = false;
							this.stockOutForm.reset();
							this.stockOutFormArray = this.fb.array([]);
							this.addstockOutForm();
							this.getStockList();
							this.isSaveBtnStatus = false;
							this.accordion.close();
							this.clearForm();
						}
					});
			} else {
				this.showToast('top-right', 'info', 'Select material to send');
			}
		}
	}

	enbaledVaidation(deliveredstatus) {
		if (deliveredstatus === '1') {
			this.stockOutForm = this.fb.group({
				vendorName: [this.stockOutForm.value.vendorName, [Validators.required]],
				date: [this.stockOutForm.value.date, [Validators.required]],
				deliveryStatus: [
					this.stockOutForm.value.deliveryStatus,
					[Validators.required],
				],
				deliveredBy: ['', [Validators.required]],
			});
		} else {
			this.stockOutForm = this.fb.group({
				vendorName: [this.stockOutForm.value.vendorName, [Validators.required]],
				date: [this.stockOutForm.value.date, [Validators.required]],
				deliveryStatus: [
					this.stockOutForm.value.deliveryStatus,
					[Validators.required],
				],
				deliveredBy: [this.stockOutForm.value.deliveredBy],
			});
		}
	}

	editStockOut(stockoutInfo, iscopy: boolean) {
		this.accordion.open();
		this.stockId = stockoutInfo.id;

		// for copy stock
		if (iscopy) {
			this.stockId = 0;
			stockoutInfo.isAcceptByBP = false;
			stockoutInfo.deliverdBy = '';
			stockoutInfo.status = 0;
		}

		this.stockOutForm.controls.vendorName.setValue(stockoutInfo.gPatnerId);
		this.stockOutForm.controls.date.setValue(
			new Date(stockoutInfo.statusUpdateDate)
		);
		this.stockOutForm.controls.challanNo.setValue(stockoutInfo.billNumber);
		this.stockOutForm.controls.deliveryStatus.setValue(stockoutInfo.status);
		this.stockOutForm.controls.deliveredBy.setValue(stockoutInfo.deliverdBy);
		this.stockOutForm.controls.isAcceptByBP.setValue(stockoutInfo.isAcceptByBP);


		// for disable save btn
		if (stockoutInfo.status === 1) {
			this.isSaveBtnStatus = true;
		} else {
			this.isSaveBtnStatus = false;
		}

		if (stockoutInfo.isAcceptByBP === true) {
			this.receivedStock = true;
		} else {
			this.receivedStock = false;
		}

		while (this.stockOutFormArray.length !== 0) {
			this.stockOutFormArray.removeAt(0);
		}
		(stockoutInfo.sout as Array<any>).forEach((element) => {
			const itemForm = this.fb.group({
				GrainId: [element.grainId, [Validators.required]],
				CategoryId: [element.grainCategoryId, [Validators.required]],
				Qty: [element.quantity, [Validators.required]],
			});
			this.getSubGrainList(element.grainId, itemForm, element.grainCategoryId);
			this.stockOutFormArray.push(itemForm);
		});
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}

	downloadVoucher(stock) {
		const url = `${environment.API_URL}/api/Report/GPartnerVoucher?StockoutId=${stock.id}`;
		this.stockService
			.getStockList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					window.open(response.data, '_blank');
				}
			});
	}
}

