import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  title: string;

  data: any;
  constructor(private dialogRef: NbDialogRef<any>,
    private gpService: GpartnerService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


  // To deactive user
  deActiveUser() {
    const url = `${environment.API_URL}/api/User/DeActiveUser?UserId=${this.data.id}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'User De-actived Successfully');

        }
      });
  }


  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }
}
