import { Component, OnInit } from '@angular/core';
import { IArea } from '../../../models/area.model';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { AreaService } from '../../../Shared/Services/area.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-area-delete-dialog',
  templateUrl: './area-delete-dialog.component.html',
  styleUrls: ['./area-delete-dialog.component.scss']
})
export class AreaDeleteDialogComponent implements OnInit {

  constructor(private areaService: AreaService,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService) { }
  title: string;
  data: IArea;
  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
  // for delete area
  deleteArea() {
    const url = `${environment.API_URL}/api/Area/DeleteArea?Id=${this.data.id}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      });
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
