import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../environments/environment';
import {  IPopupOrderDetails } from '../models/order.model';
import { ResponseModel, HttpStatusCode } from '../Shared/responsemodel';
import { GpartnerService } from '../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent implements OnInit {
  title: string;
  data: IPopupOrderDetails;
  orderId: number;
  constructor(private dialogRef: NbDialogRef<any>,
    private gpService: GpartnerService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
this.orderId = this.data.order.orderDetails.id;
  }

  close() {
    this.dialogRef.close();
  }


  // To update status
  upDateStatus() {
    const url = `${environment.API_URL}/api/Order/UpdateOrdersfromAdmin?orderId=${this.orderId}&status=${this.data.status}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'update Successfully');

        }
      });
  }


  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }
}
