import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';
import { AreaService } from '../../../Shared/Services/area.service';

@Component({
  selector: 'ngx-area-list-dialog',
  templateUrl: './area-list-dialog.component.html',
  styleUrls: ['./area-list-dialog.component.scss']
})
export class AreaListDialogComponent implements OnInit {
  title: any;
  dbid: any;

  asssingAreaStatus;
  disableAfterSave = false;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  areaList = [];

  constructor(

    private dialogRef: NbDialogRef<any>,
    private areaService: AreaService,
    private toastrService: NbToastrService,

  ) { }

  ngOnInit() {
    this.getAreaList();
    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Delivery boy');
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
  }

  close() {
    this.dialogRef.close();
  }

  getAreaList() {
    const url = `${environment.API_URL}/api/DeliveryBoy/GetDeliveryBoyArea?Id=${this.dbid}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaList = response.data.assiggedArea;
        }
      }
      );
  }

  toggle(checked: boolean, area) {
    area.assigned = checked;
  }

  saveArea() {
    this.disableAfterSave = true;
    const data = {
      assiggedArea: this.areaList,
      deliveyBoyId: this.dbid
    };
    const url = `${environment.API_URL}/api/DeliveryBoy/UpdateDeliveryBoyArea`;
    this.areaService.modifyArea(url, data).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Area Assining Successfully');
          this.disableAfterSave = true;
        } else {
          this.showToast('top-right', 'danger', 'Not Assing Area');
          this.disableAfterSave = false;

        }
      }
      );
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}

