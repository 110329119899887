import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ProductService } from '../../../Shared/Services/product.service';
import { IProduct } from '../../../models/product.model';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
	selector: 'ngx-save-product',
	templateUrl: './save-product.component.html',
	styleUrls: ['./save-product.component.scss'],
})
export class SaveProductComponent implements OnInit {
	title: string;
	data: IProduct;

	constructor(
		private dialogRef: NbDialogRef<any>,
		private productService: ProductService,
		private toastrService: NbToastrService,
	) { }

	close() {
		this.dialogRef.close();
	}

	ngOnInit() { }
	saveProduct() {
		let url = null;
		if (this.data.id === 0) {
			url = `${environment.API_URL}/api/Product/Add`;
		} else {
			url = `${environment.API_URL}/api/Product/Update`;
		}

		this.productService
			.modifyProduct(url, this.data)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					if (this.data.id === 0) {
						this.showToast('top-right', 'success', 'Added Successfully');
					} else {
						this.showToast('top-right', 'success', 'Updated Successfully');
					}
					this.close();
					this.productService.currentProductStatus(true);
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
					this.close();
				}
			});
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}
}
