import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../fp-menu-item';
import { MenuService } from '../Shared/Services/menu.service';

@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  menu = MENU_ITEMS;

  constructor(
    private menuService: MenuService
  ) { }
  ngOnInit() {
    this.menu = this.menuService.setMenu();
  }
}
