import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { EmployeeService } from '../../../Shared/Services/employee.service';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
  selector: 'ngx-employee-delete-dialog',
  templateUrl: './employee-delete-dialog.component.html',
  styleUrls: ['./employee-delete-dialog.component.scss']
})
export class EmployeeDeleteDialogComponent implements OnInit {

  title: string;

  data: any;
  constructor(
    private dialogRef: NbDialogRef<any>,
     private employeeService: EmployeeService,
     private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  deleteEmployee() {
    const url = `${environment.API_URL}/api/AdminUser/RemoveUserRegister?EmployeeId=${this.data.id}`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
