import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AddTicketsComponent } from './add-tickets/add-tickets.component';
import { TicketsComponent } from './tickets/tickets.component';
import { CareComponent } from './care.component';


const routes: Routes = [{
    path: '',
    component: CareComponent,
    children: [

        {
            path: 'add-tickets',
            component: AddTicketsComponent,
        },
        {
            path: 'tickets',
            component: TicketsComponent,
        },

    ],
}];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CareRoutingModule {
}
