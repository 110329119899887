import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-counter-order',
  templateUrl: './counter-order.component.html',
  styleUrls: ['./counter-order.component.scss']
})
export class CounterOrderComponent implements OnInit {
  
  name = 'Set iframe source';
  url: string = "https://foodwaiter.knackbe.in/#/";
  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private dialogRef: NbDialogRef<any>
  ) { 

    
  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url); 
  }


  makeOrder(sanitizer: DomSanitizer) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);     
  }

  close() {
    this.dialogRef.close();
  }

}
