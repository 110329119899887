import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CityService } from '../../Shared/Services/city.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IPopupOrderDetails } from '../../models/order.model';

@Component({
  selector: 'ngx-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {
  @ViewChild('orderItem', { static: false }) accordion;


  // @Input() public order;

  title: string;
  data: IPopupOrderDetails;

  orderId: number;
  resionsDb: any;

  resions: any[];
  constructor(
    private cityService: CityService,
    private dialogRef: NbDialogRef<any>,

  ) { }

  ngOnInit() {
   this.orderId = this.data.order.orderDetails.id;
    this.passResionBP();
    this.passResionDB();
  }
  // const total = data.orderDetails..reduce((sum, item) => sum + item.Amt, 0);

  // console.log(total);
  close() {
    this.dialogRef.close();
  }
  // for BP return reason
  passResionBP() {
    if (this.data.status === 5) {
      const url = `${environment.API_URL}/api/GPartnerPassReason/GetGPartnerPassReasonList?OrderId=
      ${this.data.order.orderDetails.id}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.resions = response.data;
          } else {
            this.resions = [];
          }
        });
    }
  }
  // for DB return reason
  passResionDB() {
    if (this.data.status === 6) {
      const url = `${environment.API_URL}/api/DeliveryBoyOrder/GetDeliveryBoypassreasonlist?OrderId=
      ${this.data.order.orderDetails.id}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.resionsDb = response.data;
          } else {
            this.resionsDb = [];
          }
        });
    }
  }
}
