import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

import { IComplain } from '../../../models/complain.model';
import { ComplainService } from '../../../Shared/Services/complain.service';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';
import { EmployeeService } from '../../../Shared/Services/employee.service';

@Component({
  selector: 'ngx-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss']
})
export class TicketDialogComponent implements OnInit {


  title: string;
  data: any;


  // Assign
  assignedUserId = 0;

  optionList = [
    { value: '1', label: 'Bad Quality' },
    { value: '2', label: 'Wrong Product' },
    { value: '3', label: 'Other' },

  ];

  statusList = [
    { id: '1', label: 'Open' },
    { id: '2', label: 'In-Process' },
    { id: '3', label: 'Resolved' },
    { id: '4', label: 'Closed' },

  ];

  status = 1;
  option = 1;

  resolveStatus;

  Discription = '';
  empList: any;
  resolutionRemark = '';
  disableresolve = false;
  disableBtnBeforeAssing = false;
  isResolved = false;

  viewMode = 'assign';

  constructor(
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<any>,
    private complainService: ComplainService,
    private employeeService: EmployeeService
  ) {


  }

  ngOnInit() {
    if (this.data.status === 0) {
      this.viewMode = 'assign';
    } else if (this.data.status === 1) {
      this.viewMode = 'inprocess';
    } else if (this.data.status === 2) {
      this.viewMode = 'resolve';
    } else if (this.data.status === 3) {
      this.viewMode = 'close';
    }
    this.getEmployeeList();
  }


  close() {
    this.dialogRef.close();
  }


  getEmployeeList() {
    const url = `${environment.API_URL}/api/AdminUser/AdminUserList`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.empList = response.data;
        }
      });
  }

  // resolve complain
  resolveComplain() {
    this.disableBtnBeforeAssing = true;
    if (this.resolutionRemark !== '') {
      const data = {
        ComplaintId: this.data.complaintId,
        Status: (this.isResolved) ? 2 : 3,
        // Status : +this.resolveStatus,
        ResolutionRemark: this.data.resolutionRemark + '  ' +
          this.resolutionRemark
      };
      let apiurl = '';
      apiurl = `${environment.API_URL}/api/Complaint/resolveTicket`;
      this.complainService.modifyComplain(apiurl, data).subscribe
        ((response: ResponseModel<IComplain>) => {
          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', ' Complain Updated Successfully');
            this.disableBtnBeforeAssing = false;

          }
        });
    }
  }

  markAsResolv() {
    this.disableBtnBeforeAssing = true;
    if (this.resolutionRemark !== '') {
      const data = {
        ComplaintId: this.data.complaintId,
        Status: (this.isResolved) ? 2 : 1,
        // Status : +this.resolveStatus,
        ResolutionRemark: this.data.resolutionRemark + '  ' + this.resolutionRemark
      };
      let apiurl = '';
      apiurl = `${environment.API_URL}/api/Complaint/resolveTicket`;
      this.complainService.modifyComplain(apiurl, data).subscribe
        ((response: ResponseModel<IComplain>) => {
          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', ' Complain Updated Successfully');
            this.disableBtnBeforeAssing = false;

          }
        });
    }
  }


  validateRemark() {
    this.disableresolve = true;
    if (this.resolutionRemark !== '') {
      this.disableresolve = false;

    }
  }

  // Assing complain
  assignComplent() {
    this.disableBtnBeforeAssing = true;
    let apiurl = '';
    apiurl =
      `${environment.API_URL}/api/Complaint/assignTicket?emp=${this.assignedUserId}&ticketId=${this.data.complaintId}`;
    this.complainService.AssignComplain(apiurl).subscribe
      ((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Complain Assigned Successfully');
          this.disableBtnBeforeAssing = false;


        }
      });
  }






  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}



