import { NbMenuItem } from '@nebular/theme';
// import { OnInit } from '@angular/core';
// import { MenuService } from './Shared/Services/menu.service';

export const MENU_ITEMS: NbMenuItem[] = [];

export const MENU_ITEMS_CustomeMenu: NbMenuItem[] =
	[
		{
			title: 'Dashboard',
			icon: 'layout-outline',
			link: '/dashboard/akdashboard',
			hidden: false
		},
		{
			title: 'FEATURES',
			group: true,
		},
		{
			title: 'Masters',
			icon: 'layout-outline',
			children: [
				{
					title: 'City',
					link: '/masters/city',
				},
				{
					title: 'Area',
					link: '/masters/area/0',
				},
				{
					title: 'Area Rate Board',
					link: '/masters/area-rate-board/0/0/0',
				},
				{
					title: 'Category',
					link: '/masters/grain',
				},
				{
					title: 'Sub-Category',
					link: '/masters/subgrain',
				},
				{
					title: 'Product',
					link: '/product/productform',
				},
				{
					title: 'Delivery Slot',
					link: '/masters/delivery-slot',
				},

				{
					title: 'Vendor',
					link: '/masters/vendor-form',
				},
				{
					title: 'Salary Grade',
					link: '/masters/salary-grad',
				},
				{
					title: 'Business Value Factor',
					link: '/masters/setting',
				},
				{
					title: ' Banner',
					link: '/product/banner',
				},
				{
					title: 'Promocode',
					link: '/master/promocode'
				}
			],
		},
		{
			title: 'User',
			icon: 'layout-outline',
			children: [
				{
					title: 'User',
					link: '/product/fk-user',
				},
				{
					title: 'COD',
					link: '/stock/cod',
				},
				{
					title: 'Applied Business Partner ',
					link: '/product/applied',
				},
				{
					title: 'Business Partner',
					link: '/product/businessPartner/0',
				},
				{
					title: 'Business Partner Rate Board',
					link: '/masters/bp-rate-board/0/0',
				},
				{
					title: 'Business Partner Stock Capacity',
					link: '/masters/gp-stock-capacity/0',
				},
				{
					title: 'Employee',
					link: '/masters/employee',
				},
				{
					title: 'Delivery Boy',
					link: '/product/deliveryboy',
				},
			],
		},
		{
			title: 'Inventory',
			icon: 'layout-outline',
			children: [
				{
					title: 'Stock In',
					link: '/stock/stockin',
				},
				{
					title: 'Stock Out',
					link: '/stock/stockout',
				},
				{
					title: 'Company Stock',
					link: '/stock/companyStock',
				},
				{
					title: 'Business Partner Stock',
					link: '/stock/gpartnerStock',
				},
				{
					title: 'Warehouse',
					link: '/stock/warehouse',
				},
				{
					title: 'Readymade Stock In',
					link: '/stock/rstockin',
				},

			],
		},
		// {
		// 	title: 'Customer Care',
		// 	icon: 'layout-outline',
		// 	children: [
		// 		{
		// 			title: 'Tickets',
		// 			link: '/care/tickets',
		// 		},
		// 		{
		// 			title: 'Add Tickets',
		// 			link: '/care/add-tickets',
		// 		},
		// 	],
		// },
		{
			title: 'Release Payment',
			icon: 'layout-outline',
			children: [

				{
					title: 'Business Partner',
					link: '/payment/rel-bp-payment',
				},
				{
					title: 'Delivery  Boy',
					link: '/payment/rel-db-payment',
				},
			],
		},

		{
			title: 'Other List',
			icon: 'layout-outline',
			children: [
				{
					title: 'Recipe',
					link: '/masters/recipe',
				},
				{
					title: 'Rating',
					link: '/masters/rating',
				},
				{
					title: 'Review',
					link: '/masters/review',
				},
				{
					title: 'Help & Support',
					link: '/masters/help',
				},
			],
		},

		{
			title: 'Reports',
			icon: 'layout-outline',
			children: [
				{
					title: 'Reports',
					link: '/masters/report',
				},

			],
		}
	];

