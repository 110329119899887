import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (localStorage.getItem('info')) {
			return true;
		} else {
			return false;
		}
	}
}
