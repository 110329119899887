import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IRateBoard } from '../../models/areaRateBoard.model';
import { ICity, IBpRateBoard } from '../../models';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-bp-rate-board',
  templateUrl: './bp-rate-board.component.html',
  styleUrls: ['./bp-rate-board.component.scss']
})
export class BpRateBoardComponent implements OnInit {
  gpRateBoardForm: FormGroup;
  gpRateBoardList: IRateBoard[] = [];
  @ViewChild('GPrateBoard', { static: false }) accordion;

  gpList = [];
  areaListByCity = [];
  gpId = 0;
  firstName = '';
  disabledBtn = false;

  pageRole: any;
  roleId: number;
  saveBtnHide = true ;


  constructor(
    private activatedroute: ActivatedRoute,
    private gpartnerService: GpartnerService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
    this.getGPList();

    this.gpId = +this.activatedroute.snapshot.paramMap.get('id');
    this.firstName = this.activatedroute.snapshot.paramMap.get('firstName');


    if (this.gpId !== 0) {
      this.getGpRateBoardList(this.gpId);
    }
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Business partner rate board');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }

  }

  gpOnChange(event) {
    this.getGpRateBoardList(this.gpId);
  }

  getGpRateBoardList(selectedGpId) {
    const url = `${environment.API_URL}/api/GrinderRateBoard/GetGrinderRateBoardList?GpId=${selectedGpId}`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<IRateBoard[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.gpRateBoardList = response.data['grains'];
        }
      });
  }

  getGPList() {
    const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.gpList = response.data;
        }
      });
  }

  saveRate() {
    this.disabledBtn = true;
    const formData: IBpRateBoard = {
      gpCode: '',
      gpName: '',
      gpId: +this.gpId,
      grains: this.gpRateBoardList
    };
    const url = `${environment.API_URL}/api/GrinderRateBoard/SaveGrinderRateBoard`;
    this.gpartnerService.modifyGPartner(url, formData).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Save Successfully');
          this.disabledBtn = false;

          // this.accordion.close();
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
          this.disabledBtn = false;

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
