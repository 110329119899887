import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	constructor(private http: HttpClient) {}

	noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };

	getPaymentList(url): Observable<any> {
		return this.http
			.get(url)
			.pipe(map((response: Response) => response as any));
	}

	modifyPayment(url, paymentInfo): Observable<any> {
		return this.http
			.post(url, paymentInfo, this.noContentType)
			.pipe(map((response: Response) => response as any));
	}

	releasePayment(url, paymentInfo): Observable<any> {
		return this.http
			.post(url, paymentInfo)
			.pipe(map((response: Response) => response as any));
	}
}
