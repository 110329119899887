import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from '../../../Shared/Services/payment.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
	selector: 'ngx-update-status',
	templateUrl: './update-status.component.html',
	styleUrls: ['./update-status.component.scss'],
})
export class UpdateStatusComponent implements OnInit {
	title: string;
	data;
	prnForm: FormGroup;
	submitted = false;

	constructor(
		private fb: FormBuilder,
		private paymentService: PaymentService,
		private dialogRef: NbDialogRef<any>,
		private toastrService: NbToastrService
	) {
		this.prnForm = this.fb.group({
			prn: ['', [Validators.required]],
		});
	}

	ngOnInit() {}

	get f() {
		return this.prnForm.controls;
	}

	updateStatus() {
		// console.log(this.data);
		this.submitted = true;
		if (this.prnForm.valid) {
			const data = {
				releasePaymentId: this.data.id,
				status: 1,
				transactionNumber: this.prnForm.value.prn,
			};
			const apiurl = `${environment.API_URL}/api/GpartnerPayment/UpdateReleasePayment`;
			this.paymentService
				.releasePayment(apiurl, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						this.close();
						this.showToast('top-right', 'success', 'Updated Successfully');
					} else {
						this.showToast('top-right', 'danger', response.statusMessage);
					}
				});
		}
	}

	close() {
		this.dialogRef.close();
	}

	showToast(position, status, message) {
		this.toastrService.show(
		  status || 'Success',
		  `${message}`,
		  { position, status });
	  }
}
