import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService, NbMenuService,
  NbSidebarService, NbThemeService,
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { ComplainService } from '../../../Shared/Services/complain.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',

})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  // userPictureOnly: boolean = false;
  user: any;
  userInfo: any;

  public notiItemsList = [{ title: 'no item found' }];
  count: any;

  notificationList: any[];

  title: string;
  data: UserData;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile', link: '/dashboard/profile-info' },
  { title: 'Log out', link: '/auth/login' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private router: Router,
    private layoutService: LayoutService,
    private complainService: ComplainService,
    private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.getNotificationList();
    // JSON.parse(localStorage.getItem('info')); user info taken  rom token
    const infostr = localStorage.getItem('info');
    const info = JSON.parse(infostr);
    this.userInfo = info;

    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      );
    // .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    setInterval(() => {
      this.getNotificationList();
    }, 5 * 60 * 1000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }


  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  // notification List
  getNotificationList() {
    const url = `${environment.API_URL}/api/Notification/AdminNotification?PageSize=0&PageNumber=0&IsSeen=false`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {

      if (response.status === HttpStatusCode.OK) {
        this.count = response.data.count;
        this.notificationList = response.data.notifications;

      } else {
        this.notificationList = [];
        this.count = 0;
      }

    });
  }

  public readNoti(item) {
    const url = `${environment.API_URL}/api/Notification/ChangeNotificationSeen?NotificationId=${item.id}`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.getNotificationList();
      }
    });

    // redirect

    let link = '';

    if (item.orderStatus === 2) {
      link = '/dashboard/current-order/' + item.id;
    }
    if (item.orderStatus === 3) {

      link = '/dashboard/accpet-order/' + item.id;
    }
    if (item.orderStatus === 5) {
      link = '/dashboard/pass-order-gp/' + item.id;
    }
    if (item.orderStatus === 7) {
      link = '/dashboard/pass-order-db/' + item.id;
    }
    if (item.orderStatus === 8) {
      link = '/dashboard/order-complete/' + item.id;
    }
    if (item.orderStatus === 13) {
      link = '/dashboard/cancel-order/' + item.id;
    }

    // this.nav

    this.router.navigateByUrl(link);

  }
}


