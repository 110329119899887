import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { ApplyService } from '../../Shared/Services/apply.service';
@Component({
  selector: 'ngx-applied',
  templateUrl: './applied.component.html',
  styleUrls: ['./applied.component.scss']
})
export class AppliedComponent implements OnInit {
  applyForm: FormGroup;
  submitted = false;
  applyList: any[];
  copyApplyList: any[];
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';

  pageSize = 50;
  pageNumber = 1;

  selectedStatus = '0';
  saveBtnHideForRole = true ;
  pageRole: any;
  roleId: number;



  constructor(
    private fb: FormBuilder,
    private applyService: ApplyService,
  ) {
    this.applyForm = this.fb.group({
      fullName: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      address: ['', [Validators.required]],

    });
  }

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'Applied Business Partner');
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
    this.getApplyList(0);
  }



  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  get f() {
    return this.applyForm.controls;
  }
  validation() {
    this.submitted = true;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.applyList = this.copyApplyList.filter(item => {
        const name = String(item.fullName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.applyList = this.copyApplyList;
    }
  }


  // get appled emp list
  getApplyList(status) {
    const url =
      `${environment.API_URL}/api/GPartner/GetAppliedGPartnerList?pageSize=0&pageNumber=0&Status=${status}`;
    this.applyService.getApplyList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {

          this.applyList = response.data;
          this.copyApplyList = response.data;

          this.config.totalItems = this.applyList.length;
        } else if (response.status === 204) {
          this.applyList = [];
        }
      });
  }

  changestatus(id, status) {
    const url = `${environment.API_URL}/api/GPartner/UpdateGPartnerStatus?id=${id}&status=${status}`;
    this.applyService.getApplyList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.getApplyList(this.selectedStatus);
        } else if (response.status === 204) {
          this.applyList = [];
        }
      });
  }

  select(event) {
    this.selectedStatus = event.target.value;
    this.getApplyList(event.target.value);
  }

}
