import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BannerService } from '../../Shared/Services/banner.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { BannerDeleteComponent } from './banner-delete/banner-delete.component';

@Component({
	selector: 'ngx-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
	TypeList = [
		{ id: 1, value: 'Default' },
		{ id: 2, value: 'Category' },
		{ id: 3, value: 'Product' },
	];
	file: File = null;
	bannerForm: FormGroup;
	bannerId = 0;
	isDisablebtn = false;
	bannerList = [];
	redirectionList = [];
	@ViewChild('banneritem', { static: false }) accordion;
	typeId: number = 0;
	submitted = false;
	redirectionId = 0;
	imagePath = null;
	hide: number = 0;
	saveBtnHide = false;

	pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

	hideShowFeild = false;

	constructor(
		public fb: FormBuilder,
		public bannerService: BannerService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService
	) {
		this.bannerForm = this.fb.group({
			typeId: [0, [Validators.required]],
			img: [''],
			redirectionId: [0],
			startDate: [''],
			endDate: [''],
			isDefault: [false],
		});
	}

	ngOnInit() {
		this.getBannerList();
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'Banner');
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}
	}

	get f() {
		return this.bannerForm.controls;
	}

	clearForm() {
		this.submitted = false;
		this.imagePath = null;
		this.fileInfo = null;
		this.file = null;
		this.bannerId = 0;
		this.bannerForm.reset();
		this.bannerForm = this.fb.group({
			typeId: [0, [Validators.required]],
			redirectionId: [0],
			startDate: [''],
			endDate: [''],
			isDefault: [false],
		});
		this.imagePath = null;
	}

	fileInfo: string;
	onFileSelect(input: HTMLInputElement): void {
		function formatBytes(bytes: number): string {
			const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			const factor = 1024;
			let index = 0;
			while (bytes >= factor) {
				bytes /= factor;
				index++;
			}
			return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
		}
		this.file = input.files[0];
		this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
	}

	getTypeId(event) {
		this.typeId = +event.target.value;
		if (event.target.value === '2') {
			this.getCategoryList();
		} else if (event.target.value === '3') {
			this.getProductList();
		} else {
			this.redirectionList = [];
		}
	}

	hideValidation(event) {
		this.hide = +event.target.value;
		if (this.hide === 1) {
			this.hideShowFeild = false;
		} else {
			this.hideShowFeild = true;

		}
	}

	clearBothList() {

		this.redirectionList = [];
	}

	getCategoryList() {
		const apiurl = `${environment.API_URL}/api/Product/GrainList`;
		this.bannerService
			.getBannerList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.redirectionList = response.data;
					if (this.redirectionId !== 0) {
						this.bannerForm.controls.redirectionId.setValue(this.redirectionId);
					}
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}

	getProductList() {
		const apiurl = `${environment.API_URL}/api/Product/TypeList?TypeId=0&GrainId=0&AreaId=0&PageSize=0&PageNumber=0`;
		this.bannerService
			.getBannerList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.redirectionList = response.data;
					if (this.redirectionId !== 0) {
						this.bannerForm.controls.redirectionId.setValue(this.redirectionId);
					}
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}

	getBannerList() {
		const apiurl = `${environment.API_URL}/api/Banner/GetBannerList`;
		this.bannerService
			.getBannerList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.bannerList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.bannerList = [];
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}


	public addBanner() {
		this.submitted = true;
		if (this.typeId === 1) {
			this.bannerForm.controls.startDate.setValidators(null);
			this.bannerForm.controls.startDate.setErrors(null);
			this.bannerForm.controls.endDate.setValidators(null);
			this.bannerForm.controls.endDate.setErrors(null);
		} else {
			this.bannerForm.controls.startDate.setValidators([Validators.required]);
			this.bannerForm.controls.startDate.updateValueAndValidity();
			this.bannerForm.controls.endDate.setValidators([Validators.required]);
			this.bannerForm.controls.endDate.updateValueAndValidity();
		}
		const formData = new FormData();
		formData.set('BannerId', String(this.bannerId));
		formData.set('RedirectionId', String(this.bannerForm.value.redirectionId));
		if (this.typeId === 1) {
			formData.set('TypeId', '1');
			formData.set('StartDate', '');
			formData.set('EndDate', '');
			formData.set('IsDefault', 'true');

		} else if (this.typeId !== 1) {
			formData.set('TypeId', String(this.bannerForm.value.typeId));
			formData.set('StartDate', this.getDate(this.bannerForm.value.startDate));
			formData.set('EndDate', this.getDate(this.bannerForm.value.endDate));
			// formData.set('IsDefault', String(this.bannerForm.value.isDefault));
			formData.set('IsDefault', 'false');


		}

		formData.set('BannerImage', this.file);
		formData.set('BannerPath', this.imagePath);
		formData.set('IsMobile', String(false));
		this.saveBtnHide = true;
		let apiurl = '';
		if (this.bannerId === 0) {
			apiurl = `${environment.API_URL}/api/Banner/AddBanner`;
		} else {
			apiurl = `${environment.API_URL}/api/Banner/UpdateBanner`;
		}
		this.bannerService
			.addBanner(apiurl, formData)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					if (this.bannerId === 0) {
						this.showToast('top-right', 'success', 'Added Successfully');
						this.fileInfo = null;
						this.file = null;
						this.getBannerList();
						this.accordion.close();
						this.submitted = false;
						this.saveBtnHide = false;
						this.bannerId = 0;

					} else {
						this.showToast('top-right', 'success', 'Updated Successfully');
						this.fileInfo = null;
						this.file = null;
						this.getBannerList();
						this.accordion.close();
						this.submitted = false;
						this.saveBtnHide = false;
						this.bannerId = 0;

					}
					this.getBannerList();
					this.bannerId = 0;
					this.bannerForm.reset();
					this.submitted = false;
					this.fileInfo = '';
					this.file = null;
					this.isDisablebtn = false;
					this.accordion.close();
				} else {
					this.showToast('top-right', 'danger', 'Mandatory fields required');
					this.isDisablebtn = false;
					this.saveBtnHide = false;

				}
			});
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

	openDeleteDialog(bannerinfo) {
		this.dialogService
			.open(BannerDeleteComponent, {
				context: {
					title: '',
					data: bannerinfo,
				},
			})
			.onClose.subscribe(() => {
				this.getBannerList();
			});
	}

	editBanner(banner) {
		if (banner.isDefault === false) {
			this.hideShowFeild = true;
		} else {
			this.hideShowFeild = false;
		}
		this.accordion.open();
		this.bannerId = banner.bannerId;
		this.bannerForm.controls.typeId.setValue(String(banner.typeId));
		this.redirectionId = banner.redirectionId;
		this.typeId = banner.typeId;
		if (banner.typeId === 2) {
			this.getCategoryList();
		} else if (banner.typeId === 3) {
			this.getProductList();
		}
		// this.bannerForm.controls.redirectionId.setValue(
		// String(banner.redirectionId)
		// );

		this.imagePath = banner.bannerPath;
		this.bannerForm.controls.startDate.setValue(new Date(banner.startDate));
		this.bannerForm.controls.endDate.setValue(new Date(banner.endDate));
		this.bannerForm.controls.isDefault.setValue(banner.isDefault);
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}
}
