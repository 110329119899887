import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { OrderService } from '../../../Shared/Services/order.service';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-ord-detail',
  templateUrl: './ord-detail.component.html',
  styleUrls: ['./ord-detail.component.scss']
})
export class OrdDetailComponent implements OnInit {

data: any;
Title: any;
orderDetails: any;

  constructor(
    private orderService: OrderService,
    private dialogRef: NbDialogRef<any>,
  ) {

   }

  ngOnInit() {
    this.getOrderDetailList(this.data);
  }

  // get order details from OrderId
  getOrderDetailList(OrderId) {
    const url = `${environment.API_URL}/api/Order/GetOrderDetail?OrderId=${OrderId}`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderDetails = response.data;
        }
      });
  }

  close() {
    this.dialogRef.close();
  }



}
