import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class SalaryGradService {
    constructor(private http: HttpClient) { }

    private isSalaryUpdate = new BehaviorSubject(false);
    getSalaryUpdateStatus = this.isSalaryUpdate.asObservable();

    currentSalaryStatus(flag) {
        this.isSalaryUpdate.next(flag);
    }

    getSalaryList(url): Observable<any> {
        return this.http
            .get(url)
            .pipe(map((response: Response) => response as any));
    }

    modifySalary(url, productInfo): Observable<any> {
        return this.http
            .post(url, productInfo)
            .pipe(map((response: Response) => response as any));
    }
}
