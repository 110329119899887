import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) { }

  getWarehouseList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }


  modifyWarehouse(url, warehouseInfo): Observable<any> {
      return this.http.post(url, warehouseInfo).pipe(map((response: Response) => response as any));
  }
}
