import { Component, OnInit } from '@angular/core';
import { ICity } from '../../../models/city.model';
import { CityService } from '../../../Shared/Services/city.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-city-delete-dialog',
  templateUrl: './city-delete-dialog.component.html',
  styleUrls: ['./city-delete-dialog.component.scss']
})
export class CityDeleteDialogComponent implements OnInit {
  constructor(private cityService: CityService,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService) { }
  title: string;
  data: ICity;
  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
  // for delete area
  deleteCity() {
    const url = `${environment.API_URL}/api/City/DeleteCity?Id=${this.data.id}`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {

        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      });
  }
  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }
}
