import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrderDetails, IOrders } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { AssingOrderDbComponent } from './assing-order-db/assing-order-db.component';


@Component({
  selector: 'ngx-pass-order-db',
  templateUrl: './pass-order-db.component.html',
  styleUrls: ['./pass-order-db.component.scss']
})
export class PassOrderDbComponent implements OnInit {

  public orderList = [];
  copyOrderList = [];
  pageRole: any;
  PassedOrderbyDeliveryBoy = false;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }



  ngOnInit() {

    this.getPassOrderListDB();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'PassedOrderbyDeliveryBoy');
    if ((this.pageRole.isWrite)) {
      this.PassedOrderbyDeliveryBoy = true;
    }
  }

  // open pop up for order details
  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
        order: order,
        status : 6,
        }

      },
    }).onClose.subscribe(() => {
      this.getPassOrderListDB();
    });
  }

  // open pop for order assing to DB
  openPopUpForAssing(order: IOrderDetails) {
    this.dialogService.open(AssingOrderDbComponent, {
      context: {
        title: '',
        data: order,
      },

    }).onClose.subscribe(() => {
      this.getPassOrderListDB();
    });

  }


  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }



  filter(str: string) {
    if (str.trim() !== '') {
      this.orderList = this.copyOrderList.filter(item => {
        const order = String(item.orderDetails.orderId).toLocaleLowerCase();
        const fName = String(item.userInfo.firstName).toLocaleLowerCase();
        const lName = String(item.userInfo.lastName).toLocaleLowerCase();
        const area = String(item.userInfo.area).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (order.includes(searchStr)) ||
          (fName.includes(searchStr)) || (lName.includes(searchStr)) || (area.includes(searchStr));
      });

    } else {
      this.orderList = this.copyOrderList;
    }
  }
  // get pass order list  DB
  getPassOrderListDB() {
    const url = `${environment.API_URL}/api/Order/GetPassedByDpOrderAdmin?PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          this.config.totalItems = this.orderList.length;
        } else {
          this.orderList = [];

        }

      });
  }

}
