import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment/payment.component';


import { DbPaymentOrderWiseComponent } from './db-payment-order-wise/db-payment-order-wise.component';
import { BpPaymentOrderWiseComponent } from './bp-payment-order-wise/bp-payment-order-wise.component';
import { RelaseBpPaymentComponent } from './relase-bp-payment/relase-bp-payment.component';
import { ReleaseDbPaymentComponent } from './release-db-payment/release-db-payment.component';

const routes: Routes = [
	{
		path: '',
		component: PaymentComponent,
		children: [

			{
				path: 'db-payment-order-wise',
				component: DbPaymentOrderWiseComponent,
			},
			{
				path: 'bp-payment-order-wise',
				component: BpPaymentOrderWiseComponent,
			},
			{
				path: 'rel-bp-payment',
				component: RelaseBpPaymentComponent,
			},
			{
				path: 'rel-db-payment',
				component: ReleaseDbPaymentComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PaymentRoutingModule {}
