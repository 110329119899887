import { Component, OnInit } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IGpHoliday } from '../../models/index';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-gp-weekly-holiday',
  templateUrl: './gp-weekly-holiday.component.html',
  styleUrls: ['./gp-weekly-holiday.component.scss']
})
export class GpWeeklyHolidayComponent implements OnInit {
  gpartnerForm: FormGroup;
  GPartnerList = [];
  submitted = false;
  workingDays = [
    { day: 0, working: false },
    { day: 1, working: false },
    { day: 2, working: false },
    { day: 3, working: false },
    { day: 4, working: false },
    { day: 5, working: false },
    { day: 6, working: false },
  ];

  toggleDay0 = false;
  toggleDay1 = false;
  toggleDay2 = false;
  toggleDay3 = false;
  toggleDay4 = false;
  toggleDay5 = false;
  toggleDay6 = false;
  GPartnerHolidayList = [];


  constructor(private gpartnerService: GpartnerService,
     private fb: FormBuilder, private toastrService: NbToastrService) {
    this.gpartnerForm = this.fb.group({
      gpId: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.getGPartnerList();
  }

  get f() {
    return this.gpartnerForm.controls;
  }


  getGPartnerList() {
    const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.GPartnerList = response.data;
        }
      });
  }

  getGPartnerHolidayList(id) {
    const url = `${environment.API_URL}/api/GPHoliday/getGpHolidayList?Gpid=${+id}`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          if (response.data.workingDays[0].working) {
            this.toggleDay0 = true;
          } else { this.toggleDay0 = false; }

          if (response.data.workingDays[1].working) {
            this.toggleDay1 = true;
          } else { this.toggleDay1 = false; }

          if (response.data.workingDays[2].working) {
            this.toggleDay2 = true;
          } else { this.toggleDay2 = false; }

          if (response.data.workingDays[3].working) {
            this.toggleDay3 = true;
          } else { this.toggleDay3 = false; }

          if (response.data.workingDays[4].working) {
            this.toggleDay4 = true;
          } else { this.toggleDay4 = false; }

          if (response.data.workingDays[5].working) {
            this.toggleDay5 = true;
          } else { this.toggleDay5 = false; }

          if (response.data.workingDays[6].working) {
            this.toggleDay6 = true;
          } else { this.toggleDay6 = false; }
        } else if (response.status === HttpStatusCode.BAD_REQUEST) {
          this.toggleDay0 = false;
          this.toggleDay1 = false;
          this.toggleDay2 = false;
          this.toggleDay3 = false;
          this.toggleDay4 = false;
          this.toggleDay5 = false;
          this.toggleDay6 = false;
        }
      });
  }


  saveHoliday() {
    this.submitted = false;
    const data: IGpHoliday = {
      gpid: +this.gpartnerForm.value.gpId,
      workingDays: this.getWorkingDays()
    };

    const url = `${environment.API_URL}/api/GPHoliday/SaveGPHoliday`;
    this.gpartnerService.modifyGPartner(url, data).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Updated Successfully');
        }
      });
  }

  getWorkingDays() {
    if (this.toggleDay0) {
      this.workingDays[0].day = 0;
      this.workingDays[0].working = true;
    } else {
      this.workingDays[0].day = 0;
      this.workingDays[0].working = false;
    }


    if (this.toggleDay1) {
      this.workingDays[1].day = 1;
      this.workingDays[1].working = true;
    } else {
      this.workingDays[1].day = 1;
      this.workingDays[1].working = false;
    }

    if (this.toggleDay2) {
      this.workingDays[2].day = 2;
      this.workingDays[2].working = true;
    } else {
      this.workingDays[2].day = 2;
      this.workingDays[2].working = false;
    }


    if (this.toggleDay3) {
      this.workingDays[3].day = 3;
      this.workingDays[3].working = true;
    } else {
      this.workingDays[3].day = 3;
      this.workingDays[3].working = false;
    }


    if (this.toggleDay4) {
      this.workingDays[4].day = 4;
      this.workingDays[4].working = true;
    } else {
      this.workingDays[4].day = 4;
      this.workingDays[4].working = false;
    }


    if (this.toggleDay5) {
      this.workingDays[5].day = 5;
      this.workingDays[5].working = true;
    } else {
      this.workingDays[5].day = 5;
      this.workingDays[5].working = false;
    }


    if (this.toggleDay6) {
      this.workingDays[6].day = 6;
      this.workingDays[6].working = true;
    } else {
      this.workingDays[6].day = 6;
      this.workingDays[6].working = false;
    }


    return this.workingDays;
  }


  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
