import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GrainService {

  constructor(private http: HttpClient) { }

  noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };

  getGrainList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }


  modifyGrain(url, grainInfo): Observable<any> {
      return this.http.post(url, grainInfo, this.noContentType).pipe(map((response: Response) => response as any));
  }
}
