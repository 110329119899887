import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../Shared/Services/payment.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
	selector: 'ngx-view-db-details',
	templateUrl: './view-db-details.component.html',
	styleUrls: ['./view-db-details.component.scss'],
})
export class ViewDbDetailsComponent implements OnInit {
	title;
	data;
	detailList = [];

	constructor(
		private paymentService: PaymentService,
		private dialogRef: NbDialogRef<any>,
		private toastrService: NbToastrService
	) {}

	ngOnInit() {
		this.getDetails();
	}

	getDetails() {
		const apiurl = `${environment.API_URL}/api/DeliveryBoyOrder/GetReleasePaymentDetail?ReleasePaymentId=${this.data.id}`;
		this.paymentService
			.getPaymentList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.detailList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.detailList = [];
				} else {
					this.showToast('top-right', 'danger');
				}
			});
	}

	close() {
		this.dialogRef.close();
	}

	showToast(position, status) {
		this.toastrService.show(status || 'Success', `Status Updated`, {
			position,
			status,
		});
	}
}
