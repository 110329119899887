import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode } from '../../../Shared/responsemodel';
import { GrainService } from '../../../Shared/Services/grain.service';

@Component({
  selector: 'ngx-seniercitien',
  templateUrl: './seniercitien.component.html',
  styleUrls: ['./seniercitien.component.scss']
})
export class SeniercitienComponent implements OnInit {
  title: string;
  docForm: FormGroup;
  file: File;
  imagePreview: string;
  userid = 75;
  isSeniorCitizen = false;
  pageRole: any;
  roleId: number;
  data: any;

  base64Matcher = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$');


  constructor(
    private dialogRef: NbDialogRef<any>,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private grainService: GrainService,
  ) {
    this.docForm = this.fb.group({
      docno: [''],
      img: [''],
      makeSenior: [false]
    });
  }

  ngOnInit() {
    this.imagePreview = this.data.scphotoUrl;



    this.isSeniorCitizen = this.data.isSeniorCitizen;
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'FlourPicker User');

    if (this.pageRole.isWrite === false) {
      this.isSeniorCitizen = true;
    }
    this.docForm.controls.docno.setValue(this.data.scRemark);
    this.docForm.controls.makeSenior.setValue(this.data.isSeniorCitizen);
  }
  get f() {
    return this.docForm.controls;
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.uploadFile(this.file);

      this.imagePreview = (fileReader.result).toString();
    },
      fileReader.readAsDataURL(this.file);
  }
  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }

  addDocument() {
    if (this.docForm.valid) {
      // this.isSaveDisabled = true;
      const formData = new FormData();

      formData.set('UserId', this.data.id.toString());
      // formData.set('actor', this.usertypeId);
      formData.set('scRemark', this.docForm.value.docno ? this.docForm.value.docno : null);
      if (this.file !== undefined) {
        formData.set('scFile', this.file !== undefined ? this.file : null);
      }

      if (this.base64Matcher.test(this.imagePreview)) {
        formData.set('imagefilepath', this.imagePreview);
      } else {
        formData.set('imagefilepath', '');
      }
      formData.set('IsSeniorCitizen', this.docForm.value.makeSenior);
      // console.log(formData);
      let apiurl = '';
      apiurl = `${environment.API_URL}/api/AdminUser/MakeSc`;
      this.grainService.modifyGrain(apiurl, formData).subscribe(
        (response: any) => {

          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', 'Make senior citizen sucessfully');
            //   this.getDoctumentList(this.usertypeId, this.userId);
            this.docForm.reset();
            this.fileInfo = '';
            this.file = null;
            // this.submitted = false;
            // this.isSaveDisabled = false;
            this.close();

          } else {
            {
              this.showToast('top-right', 'danger', response.statusMessage);
              //   this.isSaveDisabled = false;

            }
          }
        });
    }
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

  close() {
    this.dialogRef.close();
  }

}
