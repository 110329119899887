import { Injectable } from '@angular/core';
import { Promocode } from '../models/promocode.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {
 
  constructor(private http: HttpClient) { }

  private isPromocodeUpdate = new BehaviorSubject(false);
  getPromocodeUpdateStatus = this.isPromocodeUpdate.asObservable();

  getPromocodeList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }

  modifyPromocode(url, areaInfo): Observable<any> {
    return this.http.post(url, areaInfo).pipe(map((response: Response) => response as any));
}


 

}
