import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../Shared/Services/banner.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
	selector: 'ngx-banner-delete',
	templateUrl: './banner-delete.component.html',
	styleUrls: ['./banner-delete.component.scss'],
})
export class BannerDeleteComponent implements OnInit {
	title: string;
	data;

	constructor(
		private dialogRef: NbDialogRef<any>,
		private bannerService: BannerService,
		private toastrService: NbToastrService
	) {}

	ngOnInit() {}

	close() {
		this.dialogRef.close();
	}

	deletebanner() {
		const url = `${environment.API_URL}/api/Banner/DeleteBanner?Id=${this.data.bannerId}`;
		this.bannerService
			.getBannerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.showToast('top-right', 'success');
					this.close();
				}
			});
	}

	showToast(position, status) {
		this.toastrService.show(status || 'Success', `Deleted Successfully`, {
			position,
			status,
		});
	}
}
