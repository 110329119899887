import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AreaService } from '../../Shared/Services/area.service';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { IArea } from '../../models/index';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { AreaDeleteDialogComponent } from './area-delete-dialog/area-delete-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  areaForm: FormGroup;
  submitted = false;
  isSaveDisabled = false;
  CityList = [];
  picodeByCitylist: any[];
  copyPincodeByList: any[];

  AreaListByPincode = [];
  copyAreaList = [];
  id = 0;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;


  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  AreaList: IArea[] = [];
  @ViewChild('areaItem', { static: false }) accordion;

  noValidation = '^[0-9]*$';
  char = '[a-zA-Z][a-zA-Z ]+';

  pinCodeNoValidation = '^((\\+91-?)|0)?[0-9]{6}$';
  RGCValidation = '[0-9]+(\.[0-9]{1,2})?$';

  pageSize = 50;
  pageNumber = 1;
  isUpdate = false;
  disablebtn = true;
  selectedCityId: any;

  areaCityId: '';
  areaNameEng: '';

  // isvalid =

  constructor(private fb: FormBuilder,
    private areaService: AreaService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private http: HttpClient,
    private activatedroute: ActivatedRoute
  ) {
    this.areaForm = this.fb.group({
      cityId: ['', [Validators.required]],
      pinCode: ['', [Validators.required]],
      areaNameEng: ['', [Validators.required]],
      areaNameMar: ['', [Validators.required]],
      areaNameHin: ['', [Validators.required]],
      holiday: ['', [Validators.required]],
      isActive: [false],
      isAvailable: [false],
      cityNameEng: [''],
      areaCode: ['', [Validators.required]],
      RGC: ['', [Validators.required]],
      MRGC: ['', [Validators.required]],

    });
    // for disable pincode  box
    this.areaForm.controls['pinCode'].valueChanges.subscribe(tempVar => {

      if (tempVar.length === 6) {
        this.disablebtn = false;
        if (this.picodeByCitylist.findIndex(pinCode => pinCode === tempVar) < 0) {
          this.disablebtn = true;
          this.submitted = false;
        }
      } else {
        this.disablebtn = true;
      }
    });
  }

  ngOnInit() {
    this.getAreaList();
    this.getCityList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Area');
    // this.pageRole = role.page;

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }

  clearForm() {
    this.areaForm.reset();
    this.id = 0;
    this.submitted = false;
  }

  get f() {
    return this.areaForm.controls;
  }
  selectArea() {
    this.submitted = true;
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.AreaList = this.copyAreaList.filter(item => {
        const name = String(item.areaNameEng).toLocaleLowerCase();
        const city = String(item.cityNameEng).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr) || city.includes(searchStr));
      });
    } else {
      this.AreaList = this.copyAreaList;
    }
  }
  
  cityOnChange(event) {
    this.picodeByCitylist = [];
    this.selectedCityId = event.target.value;
    this.getPincodeByCity(this.selectedCityId);
  }
  // get pincode from city
  getPincodeByCity(selectedCityId) {
    this.picodeByCitylist = [];
    const url = `${environment.API_URL}/api/City/GetCityPincodes?CityId=${selectedCityId}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.picodeByCitylist = response.data;
        }
      });
  }
  getAreaList() {
    const url = `${environment.API_URL}/api/Area/GetAreaList?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.AreaList = response.data;
          this.copyAreaList = response.data;
          this.config.totalItems = this.AreaList.length;

          this.id = +this.activatedroute.snapshot.paramMap.get('id');

          if (this.id !== 0) {
            const area = this.AreaList.find(a => a.id === this.id);
            this.editArea(area);
          }

        }
      });
  }
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
    this.areaService.getAreaList(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
        }
      });
  }

  getAreaByPincode() {
    const url = `${environment.API_URL}/api/Auth/area?pincode=${this.areaForm.value.pinCode}`;
    this.http.get(url).subscribe
      ((response: ResponseModel<IArea[]>) => {
        this.AreaListByPincode = [];
        this.AreaListByPincode = response[0].postOffice;
      });
  }
  // add area
  addArea() {

    let url = '';
    if (this.areaForm.valid) {
      this.isSaveDisabled = true;
      const data: IArea = {
        id: this.id,
        cityId: +this.areaForm.value.cityId,
        areaNameEng: this.areaForm.value.areaNameEng,
        areaNameMar: this.areaForm.value.areaNameMar,
        areaNameHin: this.areaForm.value.areaNameHin,
        pinCode: this.areaForm.value.pinCode,
        areaCode: this.areaForm.value.areaCode,
        day: +this.areaForm.value.holiday,
        recipeGrindingCharges: +this.areaForm.value.RGC,
        multiGrainGrindingCharges: +this.areaForm.value.MRGC,


        areaName: '',
        isActive: (this.areaForm.value.isActive
          && this.areaForm.value.isActive !== null ? this.areaForm.value.isActive : false),

        isAvailable: (this.areaForm.value.isAvailable
          && this.areaForm.value.isAvailable !== null ? this.areaForm.value.isAvailable : false),
      };
      if (this.id === 0) {
        url = `${environment.API_URL}/api/Area/AddArea`;
      } else {
        url = `${environment.API_URL}/api/Area/UpdateArea`;
      }
      this.areaService.modifyArea(url, data).subscribe
        ((response: ResponseModel<IArea>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.isSaveDisabled = false;
            this.getAreaList();
            this.areaForm.reset();
            this.submitted = false;
            this.accordion.close();
          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isSaveDisabled = false;
          }
          this.id = 0;
          this.isUpdate = false;
        });
    }
  }


  accordaionOpenClose() {
    if (this.accordion = true) {
      this.accordion.close();
    } else if (this.accordion = false) {
      this.accordion.open();
    }
  }

  // for set value to w.r.s to feild
  editArea(area) {
    this.accordion.open();
    this.id = area.id;
    this.isUpdate = true;
    this.areaForm.controls.cityId.setValue(area.cityId);
    this.areaForm.controls.areaNameEng.setValue(area.areaNameEng);
    this.areaForm.controls.areaNameMar.setValue(area.areaNameMar);
    this.areaForm.controls.areaNameHin.setValue(area.areaNameHin);
    this.areaForm.controls.pinCode.setValue(area.pinCode);
    this.areaForm.controls.areaCode.setValue(area.areaCode);
    this.areaForm.controls.isAvailable.setValue(area.isAvailable);
    this.areaForm.controls.isActive.setValue(area.isActive);
    this.areaForm.controls.holiday.setValue(area.day);

    this.areaForm.controls.RGC.setValue(area.recipeGrindingCharges);
    this.areaForm.controls.MRGC.setValue(area.multiGrainGrindingCharges);


    this.getAreaByPincode();

    this.areaCityId = area.cityId;
    this.areaNameEng = area.areaNameEng;
  }


  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }


  openDeleteDialog(area: IArea) {
    this.accordion.close();
    this.dialogService.open(AreaDeleteDialogComponent, {
      context: {
        title: '',
        data: area,
      },
    }).onClose.subscribe(() => {
      this.getAreaList();
    });
  }
  holidays(selectedCityId) {
    if (selectedCityId === 0) {
      return ('Sunday');
    } else if (selectedCityId === 1) {
      return ('Monday');
    } else if (selectedCityId === 2) {
      return ('Tuesday');
    } else if (selectedCityId === 3) {
      return ('Wednesday');
    } else if (selectedCityId === 4) {
      return ('Thursday');
    } else if (selectedCityId === 5) {
      return ('Friday');
    } else
      if (selectedCityId === 6) {
        return ('Saturday');
      }
  }

  onKeyUpUpperCase(event) {
    if (event.target.value.length > 0) {
      event.target.value = event.target.value.toUpperCase();
    }
  }

  clearText() {

    this.AreaListByPincode = [];
  }
}
