import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { EmployeeService } from '../../Shared/Services/employee.service';

@Component({
  selector: 'ngx-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpList: any[];
  pageRole: any;
  roleId: number;
  saveBtnHide = true ;
  CopyReviewList: any[];
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.getReviewList();
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.helpList = this.CopyReviewList.filter(item => {
        const no = String(item.mobileNumber).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (no.includes(searchStr));
      });
    } else {
      this.helpList = this.CopyReviewList;
    }
  }

  getReviewList() {
    const url = `${environment.API_URL}/api/HelpAndSupport/GetHelpAndSupportList`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.helpList = response.data;
          this.CopyReviewList = response.data;
          this.config.totalItems = this.helpList.length;
        }
      });
  }

}
