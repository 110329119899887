import { Component, OnInit } from '@angular/core';
import { IPayment } from '../../models';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'ngx-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {
  title: string;
  data: IPayment;

  constructor(

		private dialogRef: NbDialogRef<any>,


  ) { }

  ngOnInit() {
  }

  close() {
		this.dialogRef.close();
	}


}
