import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryboyService } from '../../Shared/Services/deliveryboy.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { NbToastrService, NbDialogService } from '@nebular/theme';
// import { ISlot } from '../../models';
import { SlotDeleteDialogComponent } from './slot-delete-dialog/slot-delete-dialog.component';
import { ISlot } from '../../models/dbSlot.model';

@Component({
	selector: 'ngx-delivery-slot',
	templateUrl: './delivery-slot.component.html',
	styleUrls: ['./delivery-slot.component.scss']
})
export class DeliverySlotComponent implements OnInit {
	DeliverySlotFormArray: FormArray = this.fb.array([]);
	hours = [];
	minutes = [];
	slotList: ISlot[] = [];
	slotForm: FormGroup;
	slotId = 0;
	@ViewChild('slotitem', { static: false }) accordion;
	submitted = false;
	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true ;
	constructor(
		private fb: FormBuilder,
		private deliveryBoyService: DeliveryboyService,
		private toastrService: NbToastrService,
		private dialogService: NbDialogService,
	) {
		this.slotForm = this.fb.group({
			fromhr: ['', [Validators.required]],
			frommin: ['', [Validators.required]],
			fromAM: ['AM', [Validators.required]],
			tohr: ['', [Validators.required]],
			tomin: ['', [Validators.required]],
			toAM: ['AM', [Validators.required]]
		});
	}

	ngOnInit() {
		const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Delivery Slot');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }


		this.getSlotList();

		let hour = 1;
		while (hour < 13) {
			this.hours.push(hour);
			hour++;
		}

		this.minutes = [0, 15, 30, 45];
	}
	clearForm() {
		this.slotForm.reset();
		this.slotId = 0;
	}

	get f() {
		return this.slotForm.controls;
	}

	addSlotForm() {
		this.DeliverySlotFormArray.push(
			this.fb.group({
				fromhr: [],
				frommin: [],
				fromAM: [],
				tohr: [],
				tomin: [],
				toAM: []
			})
		);

	}

	getSlotList() {
		const url = `${environment.API_URL}/api/DeliverySlot/GetDeliverySlotList`;
		this.deliveryBoyService
			.getDeliveryBoyList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.slotList = response.data;
				}
			});
	}

	openDeleteDialog(slotInfo: ISlot) {

		this.accordion.close();
		this.dialogService
			.open(SlotDeleteDialogComponent, {
				context: {
					title: '',
					data: slotInfo
				}
			})
			.onClose.subscribe(() => {
				this.getSlotList();
			});
	}




	saveSlot() {

		this.submitted = true;
		if (this.slotForm.valid && this.checkforvalidSlotTime()) {
			const data: ISlot = {
				id: this.slotId,
				startHr: +this.slotForm.value.fromhr,
				startMn: +this.slotForm.value.frommin,
				endHr: +this.slotForm.value.tohr,
				endMn: +this.slotForm.value.tomin,
				startAm: this.slotForm.value.fromAM,
				endAm: this.slotForm.value.toAM,
				isActive: true,
				discription: '',
				startTime: '',
				endTime: ''
			};
			let apiurl = '';

			if (this.slotId === 0) {
				apiurl = `${environment.API_URL}/api/Deliveryslot/addDeliverySlot`;
			} else {
				apiurl = `${environment.API_URL}/api/Deliveryslot/updateDeliverySlot`;
			}

			this.deliveryBoyService
				.modifyDeliveryBoy(apiurl, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						if (this.slotId === 0) {
							this.showToast('top-right', 'success', 'Added Successfully');
						} else {
							this.showToast('top-right', 'success', 'Updated Successfully');
						}
						this.getSlotList();
						this.slotId = 0;
						this.slotForm.reset();
						this.submitted = false;
					} else {
						this.showToast('top-right', 'danger', response.statusMessage);
					}
				});
		} else {
			this.showToast('top-right', 'danger', 'Invalid Time or Selection start time may be large');
		}
	}

	editSlot(slotInfo: ISlot) {
		this.accordion.open();
		this.slotId = slotInfo.id;
		this.slotForm.controls.fromhr.setValue(slotInfo.startHr);
		this.slotForm.controls.frommin.setValue(slotInfo.startMn);
		this.slotForm.controls.fromAM.setValue(slotInfo.startAm);
		this.slotForm.controls.tohr.setValue(slotInfo.endHr);
		this.slotForm.controls.tomin.setValue(slotInfo.endMn);
		this.slotForm.controls.toAM.setValue(slotInfo.endAm);
	}

	getSlotInfo(slotArray: FormArray) {
		const tempArray = [];
		slotArray.controls.forEach((form: FormGroup) => {
			const obj = {
				id: form['id'],
				startHr: +form.value.fromhr,
				startMn: +form.value.frommin,
				endHr: +form.value.tohr,
				endMn: +form.value.tomin,
				startAm: form.value.fromAM,
				endAm: form.value.toAM,
				isActive: true,
				discription: '',
				startTime: '',
				endTime: ''
			};

			tempArray.push(obj);
		});

		return tempArray;
	}


	activeSlot(id) {
		const url = `${environment.API_URL}/api/DeliverySlot/ActiveDeliverySlot?Id=${id}`;
		this.deliveryBoyService
			.getDeliveryBoyList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.showToast('top-right', 'success', 'Activeted Successfully');
					this.getSlotList();

				}
			});
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status
		});
	}

	checkforvalidSlotTime() {

		let startHr = +this.slotForm.value.fromhr;
		const startMn = +this.slotForm.value.frommin;

		let endHr = +this.slotForm.value.tohr;
		const endMn = +this.slotForm.value.tomin;

		const startAm = (this.slotForm.value.fromAM).toString();
		const endAm = (this.slotForm.value.toAM).toString();

		let startDay = 1;
		let endDay = 1;

		// const day = 1;

		if (startAm.toLowerCase() === 'pm' && startHr !== 12) {
			startHr = startHr + 12;
			if (startHr === 24) {
				startHr = 0;
				startDay = 2;
			}
		} else {
			if (startAm.toLowerCase() === 'am' && startHr === 12) {
				startHr = 0;
				startDay = 2;
			}
		}

		if (endAm.toLowerCase() === 'pm') {
			endHr = endHr + 12;
			if (endHr === 24) {
				endHr = 0;
				endDay = 2;
			}
		}

		const startDate = new Date(2020, 1, startDay, startHr, startMn);

		const endDate = new Date(2020, 1, endDay, endHr, endMn);

		if (startDate < endDate) {
			return true;
		} else {
			return false;
		}
	}
}
