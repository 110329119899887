import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { CityService } from '../../../Shared/Services/city.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ISubGrain } from '../../../models';

@Component({
  selector: 'ngx-report-popup',
  templateUrl: './report-popup.component.html',
  styleUrls: ['./report-popup.component.scss']
})
export class ReportPopupComponent implements OnInit {
  title: '';
  data: any;
  message: any;
  orderMessage: string;
  errorMessage: any ;
  categoerylist: ISubGrain[] = [];
  reportForm: FormGroup;
  submitted = false;



  constructor(
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<any>,
    private cityService: CityService
  ) {
    this.reportForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      orderNo: ['', [Validators.required]],
      type: ['',[Validators.required]],
    });
  }

  ngOnInit() {
    this.getCategoeryList();
  }

  get f() {
    return this.reportForm.controls;
  }

  getDate(dateinfo) {
    const day = dateinfo.getDate().toString();
    const year = dateinfo.getFullYear().toString();
    const month = (dateinfo.getMonth() + 1).toString();
    const dateStr =
      year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    return dateStr;
  }
  getCategoeryList() {
		const url = `${environment.API_URL}/api/GrainCategory/getAllActiveGrainCategory`;
		this.cityService
      .getCityList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.categoerylist = response.data;
				}
			});
	}
  getOrderDetailsReport() {
    this.submitted = true;
    if (this.reportForm.controls.orderNo.valid) {
      const url = `${environment.API_URL}/api/Auth/OrderInDetail?OrderId=${this.reportForm.value.orderNo}`;
      this.cityService
        .getCityList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            window.open(response.data, '_blank');
          } else if (response.status === 400) {
            this.errorMessage = 'Incorrect Order number';
          } else {
            this.message === 'Enter Valid Order No';
          }
        });
    }
  }
  getItemReport(){

    const url = `${environment.API_URL}/api/ExcelReport/ItemWiseReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');

        } else {

        }
      });
  }

  getCategoeryReport(){

    const url = `${environment.API_URL}/api/ExcelReport/CategoryWiseReport?grindingType=${this.reportForm.value.type}&StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');

        } else {

        }
      });
  }
  getUserWallet() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}/api/ExcelReport/GetUserUsedWalletReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Not found wallet this slot';
        }

      });
  }

  getSorcePay() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}/api/ExcelReport/GetSourceofpaymentofwalletReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Not found wallet this slot';
        }

      });
  }

  getOrderNoWithCharge() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}/api/ExcelReport/GetOrderNumberWithChargesReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'yet no order completed..!';
        }

      });
  }

  getPassOrderByDay() {
    this.submitted = true;
    const url = `${environment.API_URL}/api/ExcelReport/GetDayPassedOrderedUserReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'No order Passed';
        }

      });
  }

  getMuliGrainRecipeOrderReport() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}/api/ExcelReport/GetMultiGrainandRecipeOrderReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'No Multigrain and Recipe order';
        }

      });
  }

  getCompleteOrder() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}​/api/ExcelReport/GetCompletedOrderReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Order Yet Not Completed';
        }

      });
  }


  getKmReport() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}​/api/ExcelReport/GetDeliveryBoyKmTravelReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'no delivery boy Pick-up Order';
        }

      });
  }
  // Purchase Report
  getPurchase() {
    this.submitted = true;
    // tslint:disable-next-line: max-line-length
    const url = `${environment.API_URL}​/api/ExcelReport/PurchaseReport?StartDate=${this.getDate(this.reportForm.value.startDate)}&EndDate=${this.getDate(this.reportForm.value.endDate)}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
        } if (response.status === 204) {
          this.message = 'Not any transaction ..!';
        }

      });
  }
  // close
  closeDialog() {
    this.dialogRef.close();
  }
}
