import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from '../fp-menu-item';
import {MenuService} from '../../app/Shared/Services/menu.service';

@Component({
  selector: 'ngx-Care',
  templateUrl: './care.component.html',
  styleUrls: ['./care.component.scss']
})
export class CareComponent implements OnInit {
  menu = MENU_ITEMS;

  constructor(
  private  menuService: MenuService
  ) { }

  ngOnInit() {
    this.menu =   this.menuService.setMenu();
  }

}
