import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { NbToastrService } from '@nebular/theme';
import { SalaryGradService } from '../../Shared/Services/salaryGrad.service';
import { ISalaryGrad } from '../../models/salaryGrad.model';
@Component({
  selector: 'ngx-salary-grad',
  templateUrl: './salary-grad.component.html',
  styleUrls: ['./salary-grad.component.scss']
})
export class SalaryGradComponent implements OnInit {

  @ViewChild('salaryGradForm', { static: false }) accordion;
  salaryGradeForm: FormGroup;
  submitted = false;
  salarysList: any[];
  pageRole: any;
  roleId: number;
  saveBtnHide = true ;
  id = 0;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };
  numaricPattern = '^[0-9]+(.[0-9]{0,2})?$';
  copySalaryList: ISalaryGrad[];

  constructor(
    private fb: FormBuilder,
    private salaryGradService: SalaryGradService,
    private toastrService: NbToastrService,
  ) {

    this.salaryGradeForm = this.fb.group({
      gradecode: ['', [Validators.required]],
      salary: ['', [Validators.required]],
      isActive: [false]
    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Salary Grade ');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }

    this.getSalaryGradList();
  }
  get f() {
    return this.salaryGradeForm.controls;
  }
  validation() {
    this.submitted = true;
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }
  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.salarysList = this.copySalaryList.filter((payment) => {
        return (String(payment.gradecode).toLowerCase()).startsWith(str.toLowerCase().trim());
      });
      this.config.totalItems = this.copySalaryList.length;
    } else {
      this.salarysList = this.copySalaryList;
      this.config.totalItems = this.copySalaryList.length;
    }
  }
  // get salary grade  list
  getSalaryGradList() {
    const url = `${environment.API_URL}/api/Grade/GetGradeList`;
    this.salaryGradService.getSalaryList(url).subscribe
      ((response: ResponseModel<ISalaryGrad[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.salarysList = response.data;
          this.copySalaryList = response.data;
        }
      });
  }
  // for set value to w.r.s to feild
  setValueToForm(payment) {
    this.accordion.open();
    this.id = payment.id;
    this.salaryGradeForm.controls.gradecode.setValue(payment.gradecode);
    this.salaryGradeForm.controls.salary.setValue(payment.salary);
    this.salaryGradeForm.controls.isActive.setValue(payment.isActive);

  }
  // add City
  addUpdateSalaryGrad() {
    const data = {
      id: this.id,
      gradecode: this.salaryGradeForm.value.gradecode,
      salary: +this.salaryGradeForm.value.salary,
      isActive: this.salaryGradeForm.value.isActive,
    };

    if (this.salaryGradeForm.valid) {
      let apiurl = '';
      if (this.id === 0) {
        apiurl = `${environment.API_URL}/api/Grade/AddGrade`;
      } else {
        apiurl = `${environment.API_URL}/api/Grade/UpdateGrade`;
      }
      this.salaryGradService.modifySalary(apiurl, data).subscribe
        ((response: ResponseModel<ISalaryGrad>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.salaryGradeForm.reset();
            this.submitted = false;
            this.getSalaryGradList();
            this.accordion.close();


            this.id = 0;
          } else {
            if (response.status === 400) {
              this.showToast('top-right', 'danger', response.statusMessage);
              // this.isSaveDisabled = false;
            }
            // this.isSaveDisabled = false;
            this.id = 0;
          }
        });
    }
  }
  clearForm() {
    this.salaryGradeForm.reset();

    this.submitted = false;
    this.id = 0;
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
