import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class SettingService {
    constructor(private http: HttpClient) { }

    private isSettingUpdate = new BehaviorSubject(false);
    getSettingUpdateStatus = this.isSettingUpdate.asObservable();

    currentSettingStatus(flag) {
        this.isSettingUpdate.next(flag);
    }

    getSettingList(url): Observable<any> {
        return this.http
            .get(url)
            .pipe(map((response: Response) => response as any));
    }

    modifySetting(url, productInfo): Observable<any> {
        return this.http
            .post(url, productInfo)
            .pipe(map((response: Response) => response as any));
    }
}
