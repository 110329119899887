import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
// import { environment } from '../../../../environments/environment';
// import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
// import { ReadymadeService } from '../../../Shared/Services/readymade.service';

@Component({
  selector: 'ngx-readymade-stock-delete-dialog',
  templateUrl: './readymade-stock-delete-dialog.component.html',
  styleUrls: ['./readymade-stock-delete-dialog.component.scss']
})
export class ReadymadeStockDeleteDialogComponent implements OnInit {

  title: string;
  data: any;

  constructor(private dialogRef: NbDialogRef<any>,
    // private readyMadeStockService: ReadymadeService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
