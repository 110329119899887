import { Component, OnInit } from '@angular/core';
import { ISlot } from '../../../models/index';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { DeliveryboyService } from '../../../Shared/Services/deliveryboy.service';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
	selector: 'ngx-slot-delete-dialog',
	templateUrl: './slot-delete-dialog.component.html',
	styleUrls: ['./slot-delete-dialog.component.scss']
})
export class SlotDeleteDialogComponent implements OnInit {
	title: string;
	data: ISlot;
	constructor(
		private dialogRef: NbDialogRef<any>,
		private deliveryBoyService: DeliveryboyService,
		private toastrService: NbToastrService
	) { }

	ngOnInit() { }

	close() {
		this.dialogRef.close();
	}

	deleteSlot() {
		const url = `${environment.API_URL}/api/DeliverySlot/DeleteDeliverySlot?Id=${this.data.id}`;
		this.deliveryBoyService
			.getDeliveryBoyList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.showToast('top-right', 'success');
					this.close();
				}
			});
	}


	showToast(position, status) {
		this.toastrService.show(status || 'Success', `De-activeted Successfully`, {
			position,
			status
		});
	}
}
