import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { ISetting } from '../../models';
import { SettingService } from '../../Shared/Services/setting.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'ngx-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  @ViewChild('pproccedForm', { static: false }) accordion;
  proccedForm: FormGroup;
  id = 0;
  pageRole: any;
  roleId: number;
  saveBtnHide = true ;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  submitted = false;
  settingList: any[];
  copySettingList: any[];
  constructor(
    private fb: FormBuilder,
    private settingService: SettingService,
    private toastrService: NbToastrService,

  ) {
    this.proccedForm = this.fb.group({
      name: [''],
      settingKey: [''],
      settingValue: ['', [Validators.required]],
      disc: ['', [Validators.required]],

    });
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Business Value Factor');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }

    this.getSettingList();
  }
  get f() {
    return this.proccedForm.controls;
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }



  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.settingList = this.copySettingList.filter(item => {
        const name = String(item.name).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.settingList = this.copySettingList;
    }
  }

  validation() {
    this.submitted = true;
  }
  // get setting  list
  getSettingList() {
    const url = `${environment.API_URL}/api/ProSet/GetList`;
    this.settingService.getSettingList(url).subscribe
      ((response: ResponseModel<ISetting[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.settingList = response.data;
          this.copySettingList = response.data;
          this.config.totalItems = this.settingList.length;


        }
      });
  }
  // for set value to w.r.s to feild
  setValueToForm(set) {
    this.accordion.open();
    this.id = set.id;
    this.proccedForm.controls.name.setValue(set.name);
    this.proccedForm.controls.settingValue.setValue(set.settingValue);
    this.proccedForm.controls.settingKey.setValue(set.settingKey);
    this.proccedForm.controls.disc.setValue(set.description);
  }
  // add City
  addUpdateSet() {
    const data = {
      id: this.id,
      name: this.proccedForm.value.name,
      settingKey: this.proccedForm.value.settingKey,
      settingValue: this.proccedForm.value.settingValue,
      description: this.proccedForm.value.disc
    };

    if (this.proccedForm.valid) {
      let apiurl = '';
      if (this.id === 0) {
        apiurl = `${environment.API_URL}/api/ProSet/AddProSet`;
      } else {
        apiurl = `${environment.API_URL}/api/ProSet/UpdateProSet`;
      }
      this.settingService.modifySetting(apiurl, data).subscribe
        ((response: ResponseModel<ISetting>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.proccedForm.reset();
            this.submitted = false;
            this.getSettingList();
            this.accordion.close();
            this.id = 0;
          } else {
            if (response.status === 400) {
              this.showToast('top-right', 'danger', response.statusMessage);
              // this.isSaveDisabled = false;
            }
            // this.isSaveDisabled = false;
            this.id = 0;
          }
        });
    }
  }

  clearForm() {
    this.proccedForm.reset();
    this.submitted = false;
    this.id = 0;
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
