import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FpdashboardComponent } from './fpdashboard/fpdashboard.component';
import { PassOrderComponent } from './pass-order/pass-order.component';
import { CurrentRunningOrderComponent } from './current-running-order/current-running-order.component';
import { CancelorderComponent } from './cancelorder/cancelorder.component';
import { TodaysorderComponent } from './todaysorder/todaysorder.component';
import { OrderInProgressComponent } from './order-in-progress/order-in-progress.component';
import { OrderCompleteComponent } from './order-complete/order-complete.component';
import { DashboardComponent } from './dashboard.component';
import { AccpetOrderComponent } from './accpet-order/accpet-order.component';
import { PickUpOrderComponent } from './pick-up-order/pick-up-order.component';
import { OrderComplitedByDbComponent } from './order-complited-by-db/order-complited-by-db.component';
import { PassOrderGpComponent } from './pass-order-gp/pass-order-gp.component';
import { PassOrderDbComponent } from './pass-order-db/pass-order-db.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { WaiterOrderComponent } from './waiter-order/waiter-order.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: '',
                redirectTo: 'akdashboard',
                pathMatch: 'full'
            },

            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'akdashboard',
                component: FpdashboardComponent
            },
            {
                path: 'pick-up-order/:0',
                component: PickUpOrderComponent
            },
            {
                path: 'pass-order-gp/:0',
                component: PassOrderGpComponent
            },
            {
                path: 'pass-order-db/:0',
                component: PassOrderDbComponent
            },

            {
                path: 'order-complited-by-db/:0',
                component: OrderComplitedByDbComponent
            },
            {
                path: 'current-order/:0',
                component: CurrentRunningOrderComponent,
            },

            {
                path: 'accpet-order/:0',
                component: AccpetOrderComponent,
            },
            {
                path: 'pass-order/:0',
                component: PassOrderComponent,
            },
            {
                path: 'cancel-order/:0',
                component: CancelorderComponent,
            },
            {
                path: 'todays-order/:0',
                component: TodaysorderComponent,
            },
            {
                path: 'inProgress-order/:0',
                component: OrderInProgressComponent,
            },
            {
                path: 'order-complete/:0',
                component: OrderCompleteComponent,
            },
            {
                path: 'profile-info',
                component: ProfileInfoComponent,
            },
            {
                path: 'waiter_order',
                component: WaiterOrderComponent
              },

        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {
}
