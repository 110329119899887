import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-db-payment-order-wise',
  templateUrl: './db-payment-order-wise.component.html',
  styleUrls: ['./db-payment-order-wise.component.scss']
})
export class DbPaymentOrderWiseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
