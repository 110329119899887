import { Component, OnInit } from '@angular/core';
import { ReportPopupComponent } from './report-popup/report-popup.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { CityService } from '../../Shared/Services/city.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';

@Component({
  selector: 'ngx-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  showloder1 = false;
  showloder2 = false;
  showloder3 = false;
  showloder4 = false;
  showloder5 = false;
  showloder6 = false;
  showloder7 = false;
  showloder8 = false;
  showloder9 = false;
  showloder10 = false;
  showloder11 = false;
  showloder12 = false;
  showloder13 = false;
  showloder14  = false;
  showloder15  = false;
  showloder16  = false;


  hide1 = true;
  hide2 = true;
  hide3 = true;
  hide4 = true;
  hide5 = true;
  hide6 = true;
  hide7 = true;
  hide8 = true;
  hide9 = true;
  hide10 = true;
  hide11 = true;
  hide12 = true;
  hide13 = true;

  pageRole: any;
  roleId: number;
  allReportHide = false;
  UserReports = false;
  BusinessPartnerReport = false;
  BusinessPartnerStockReport = false;
  BusinessPartnerConsumeStockReport = false;
  BusinesspartnerrateboardReport = false;
  CurrentOrderForDeliveryBoy = false;
  AreaStock = false;
  OrderReports = false;

  UserWallet = false;
  SourceOfPayment = false;
  OrderNumberwithCharges = false;
  PassOrder = false;
  CompletedOrders = false;
  DeliveryBoyKm = false;

  saleReport = false;
  MultigrainandRecipeOrderReport = false;
  InventoryReport = false;
  PurchaseReport = false;
  CustomerwithZeroOrdersRepport = false;
  CurrentOrderforGrindingPartnerReport = false;



  constructor(
    private dialogService: NbDialogService,
    private cityService: CityService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'All reports');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.allReportHide = true;
    }

    this.pageRole = role.page.find(m => m.page === 'User Reports');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.UserReports = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.BusinessPartnerReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner StockReport');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.BusinessPartnerStockReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business Partner Consume Stock');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.BusinessPartnerConsumeStockReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Business partner rate board');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.BusinesspartnerrateboardReport = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Current order for delivery boy');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.CurrentOrderForDeliveryBoy = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Area stock');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.AreaStock = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Order Reports');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.OrderReports = true;
    }

    this.pageRole = role.page.find(m => m.page === 'User Wallet');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.UserWallet = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Source of payment');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.SourceOfPayment = true;
    }

    this.pageRole = role.page.find(m => m.page === 'Order Number with Charges');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.OrderNumberwithCharges = true;
    }
    this.pageRole = role.page.find(m => m.page === 'Pass order by day');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.PassOrder = true;
    }
    this.pageRole = role.page.find(m => m.page === '​Completed Order');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.CompletedOrders = true;
    }
    this.pageRole = role.page.find(m => m.page === 'Delivery Boy Km travel');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.DeliveryBoyKm = true;
    }

    this.pageRole = role.page.find(m => m.page === 'SaleReport');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.saleReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'MultigrainandRecipeOrderReport');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.MultigrainandRecipeOrderReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'InventoryReport');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.InventoryReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'PurchaseReport');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.PurchaseReport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'CustomerwithZeroOrders');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.CustomerwithZeroOrdersRepport = true;
    }
    this.pageRole = role.page.find(m => m.page === 'CurrentOrderforGrindingPartner');
    if (this.pageRole.isWrite || this.pageRole.isRead  ) {
      this.CurrentOrderforGrindingPartnerReport = true;
    }




  }
  openItemDialog() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 9,
        }
      },
    });
  }
  openCategoeryDialog() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 10,
        }
      },
    });
  }
  openOrderDia() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 0,
        }
      },
    });
  }

  openWalleDialog() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 1,
        }
      },
    });
  }
  openSorcePayment() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 2,
        }
      },
    });
  }

  openOrderWirhCharges() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 3,
        }
      },
    });
  }

  // openPassOrder() {
  //   this.dialogService.open(ReportPopupComponent, {
  //     context: {
  //       title: '',
  //       data: {
  //         status: 4,
  //       }
  //     },
  //   });
  // }


  oepnMultigrainDiaForRecipe() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 5,
        }
      },
    });
  }


  openCompleteOrederDia() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 6,
        }
      },
    });
  }

  openKm() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 7,
        }
      },
    });
  }

  openPurchase() {
    this.dialogService.open(ReportPopupComponent, {
      context: {
        title: '',
        data: {
          status: 8,
        }
      },
    });
  }

  getPriceReport(){

    this.showloder14 = true;
    const url = `${environment.API_URL}/api/ExcelReport/PriceList`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder14 = false;

        } else {
          this.showloder14 = false;

        }
      });
  }

   getUserReport() {
    this.showloder1 = true;
    this.hide1 = false;
    const url = `${environment.API_URL}/api/ExcelReport/userReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder1 = false;
          this.hide1 = true;

        } else {
          this.showloder1 = false;
          this.hide1 = true;

        }
      });
  }

  getBPReport() {
    this.showloder2 = true;
    this.hide2 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder2 = false;
          this.hide2 = true;
        } else {
          this.showloder2 = false;
          this.hide2 = true;
        }
      });
  }

  getBPStoctReport() {
    this.showloder3 = true;
    this.hide3 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder3 = false;
          this.hide3 = true;
        } else {
          this.showloder3 = false;
          this.hide3 = true;
        }
      });
  }

  getBPConsumeReport() {
    this.showloder4 = true;
    this.hide4 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GPartnerStockConsumeReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder4 = false;
          this.hide4 = true;
        } else {
          this.showloder4 = false;
          this.hide4 = true;
        }
      });
  }

  getAreaStockReport() {
    this.showloder9 = true;
    this.hide9 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetExcelAreaStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder9 = false;
          this.hide9 = true;
        } else {
          this.showloder9 = false;
          this.hide9 = true;
        }
      });
  }

  // Customer with Zero Orders
  getCustomerwithZeroOrders() {
    this.showloder10 = true;
    this.hide10 = false;
    const url = `${environment.API_URL}/api/ExcelReport/CustomerReportwithZeroOrders`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder10 = false;
          this.hide10 = true;
        } else {
          this.showloder10 = false;
          this.hide10 = true;
        }
      });
  }

  // Stock_In
  getStock_In() {
    this.showloder11 = true;
    this.hide11 = false;
    const url = `${environment.API_URL}/api/ExcelReport/StockinReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder11 = false;
          this.hide11 = true;
        } else {
          this.showloder11 = false;
          this.hide11 = true;
        }
      });
  }


  // get BP rate board report
  getBPRateBoardReport() {
    this.showloder6 = true;
    this.hide6 = false;
    const url = `${environment.API_URL}/api/ExcelReport/GetGPRateBoardReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder6 = false;
          this.hide6 = true;
        } else {
          this.showloder6 = false;
          this.hide6 = true;
        }
      });
  }

  // get DB delivery order report
  getCurrentOrderForDeliveryBoy() {
    this.showloder7 = true;
    this.hide7 = false;
    const url = `${environment.API_URL}​/api/ExcelReport/GetCurrentOrderforDeliveryBoyReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder7 = false;
          this.hide7 = true;
        } else {
          this.showloder7 = false;
          this.hide7 = true;
          this.showToast('top-right', 'success', 'No Current order report for DB');

        }
      });
  }
  // get BP grinding order report
  getGrindingReportCurrentOrder() {
    this.showloder8 = true;
    this.hide8 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/GetCurrentOrderforGrindingPartnerReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder8 = false;
          this.hide8 = true;
        } else {
          this.showloder8 = false;
          this.hide8 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get BP all stock report
  getAllBPStockReport() {
    this.showloder5 = true;
    this.hide5 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/AllBussinessPartnerStockReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder5 = false;
          this.hide5 = true;
        } else {
          this.showloder5 = false;
          this.hide5 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get Inventory report
  getInventoryReport() {
    this.showloder12 = true;
    this.hide12 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/Inventory`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder12 = false;
          this.hide12 = true;
        } else {
          this.showloder12 = false;
          this.hide12 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  // get pass order by user  report
  getPassOrderByUser() {
    this.showloder13 = true;
    this.hide13 = false;
    const url = `${environment.API_URL}​​/api/ExcelReport/GetDayPassedOrderedUserReport`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          window.open(response.data, '_blank');
          this.showloder13 = false;
          this.hide13 = true;
        } else {
          this.showloder13 = false;
          this.hide13 = true;
          this.showToast('top-right', 'success', 'No Current order report');

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status
    });
  }


}
