import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import {  NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormGroup } from '@angular/forms';
import { IComplain } from '../../../models/complain.model';
import { ComplainService } from '../../../Shared/Services/complain.service';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

import { EmployeeService } from '../../../Shared/Services/employee.service';

@Component({
  selector: 'ngx-add-ticket.dialog',
  templateUrl: './add-ticket.dialog.component.html',
  styleUrls: ['./add-ticket.dialog.component.scss']
})
export class AddticketDialogComponent implements OnInit, AfterViewInit {
  title: string;
  data: any;

  addComplainForm: FormGroup;
  isDisablebtn = false;
  submitted = false;
  comList: IComplain[] = [];
  complaintId = 0;


  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  @ViewChild('comitem', { static: false }) accordion;

  copycomList: IComplain[] = [];

  empList: any[];



  viewMode = 'add';

  complentType = '1';
  description = '';
  savedisabbled = false;

  constructor(
    private complainService: ComplainService,
    private dialogRef: NbDialogRef<any>,
    private employeeService: EmployeeService,
    private toastrService: NbToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    ) {


  }


  ngOnInit() {



    // this.getEmployeeList();

  }
  clearForm() {
    this.addComplainForm.reset();

  }

  validaion() {
    this.submitted = true;
  }


  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
    if (this.data.complaintId === 0) {
      this.viewMode = 'add';
    } else {
      this.viewMode = 'view';
    }
  }

  get f() {
    return this.addComplainForm.controls;
  }


  getEmployeeList() {
    const url = `${environment.API_URL}/api/AdminUser/AdminUserList`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.empList = response.data;
        }
      });
  }

  validateform(value) {

    const selectedvalue = +value;

    if (selectedvalue === 3) {
      if (this.description === '') {
        this.savedisabbled = true;
      } else {
        this.savedisabbled = false;
      }
    } else {
      this.savedisabbled = false;
    }

  }


  addComplain() {

    this.savedisabbled = true;

    const data: IComplain = {
      ComplaintId: 0,
      OrderId: this.data.orderId,
      ComplaintType: +this.complentType,
      UserId: this.data.userId,
      Discription: this.description,
    };

    let apiurl = '';
    if (this.complaintId === 0) {
      apiurl = `${environment.API_URL}/api/Complaint/AddComplaint`;
    }

    this.complainService.modifyComplain(apiurl, data).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          if (this.complaintId === 0) {
            this.showToast('top-right', 'success', 'Added Successfully');
          } else {
            this.showToast('top-right', 'success', 'Updated Successfully');
          }

        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
          this.isDisablebtn = false;

        }

        this.savedisabbled = true;

      });
  }


  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

  close() {
    this.dialogRef.close();
  }

}





