import { Component, OnInit } from '@angular/core';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { CodDialogComponent } from './cod-dialog/cod-dialog.component';

@Component({
  selector: 'ngx-cod',
  templateUrl: './cod.component.html',
  styleUrls: ['./cod.component.scss']
})
export class CodComponent implements OnInit {

  codUserList: any[];
  unPaidUserList: any[];
  copyUserList: any[];
  copyDeActiveUserList: any[] = [];

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;


  user = 0;
  userId = 0;
  activeDeactiveBtn = false;

  pageSize = 10;
  pageNumber = 1;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };


  constructor(
    private toastrService: NbToastrService,
    private gpService: GpartnerService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.getPaidFkUser();
    this.getUnPaidUSerList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
    this.pageRole = role.page.find(m => m.page === 'FlourPicker User');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
    this.getPaidFkUser();
  }

  filter1(str: string) {
    str = str.trim();
    if (str !== '') {
      this.unPaidUserList = this.copyDeActiveUserList.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const pincode = String(item.mobile).toLocaleLowerCase();
        const mobile = String(item.pincode).toLocaleLowerCase();
        const area = String(item.areaName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) ||
          (pincode.includes(searchStr)) ||
          (mobile.includes(searchStr)) ||
          (area.includes(searchStr));
      });
    } else {
      this.unPaidUserList = this.copyDeActiveUserList;
    }
  }
  // activated user list
  getPaidFkUser() {
    this.codUserList = [];
    const url = `${environment.API_URL}/api/DeliveryBoyOrder/CODOrders?IsCredited=true&PageSize=0&pageno=0`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.codUserList = response.data.cods;
          // this.getDeActiveUserList();
          this.copyUserList = response.data.cods;
          // this.config.totalItems = this.userList.length;
          this.config.totalItems = response.data.count;
        }
      });
  }

  filter3(str: string) {
    str = str.trim();
    if (str !== ' ') {
      const url = `${environment.API_URL}/api/User/GetUserListSearch?SearchStr=${str}`;
      this.gpService
        .getGPartnerList(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.codUserList = response.data.users;
            this.copyUserList = response.data.users;
          } else {
            this.getPaidFkUser();
          }
        });
    }
  }
  changeTag(event) {
    if (event.tabId === 'paidUser') {
      this.getPaidFkUser();
    } else {
      this.getUnPaidUSerList();
    }
  }
  // de-activated user list

  getUnPaidUSerList() {
    this.unPaidUserList = [];
    const url = `${environment.API_URL}/api/DeliveryBoyOrder/CODOrders?IsCredited=false&PageSize=0&pageno=0`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.unPaidUserList = response.data.cods;
          this.copyDeActiveUserList = response.data.cods;
          this.getPaidFkUser();
        }
      });
  }


  openCodDialogBox(user: any, flag) {
    this.dialogService.open(CodDialogComponent, {
      context: {
        title: '',
        data: user,
        flag: flag
      },
    }).onClose.subscribe(() => {
      this.getPaidFkUser();
      this.getUnPaidUSerList();
    });
  }

  // To active user
  activeUser(userId) {
    this.activeDeactiveBtn = true;
    const url = `${environment.API_URL}/api/User/ActiveUser?UserId=${userId}`;
    this.gpService
      .getGPartnerList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.getUnPaidUSerList();
          this.getPaidFkUser();
          this.activeDeactiveBtn = false;
          this.showToast('top-right', 'success', 'User Activeted Successfully');
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

}
