import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

  userInfo: any;
  constructor() {

  }

  ngOnInit() {
    // JSON.parse(localStorage.getItem('info')); user info taken  from token
    const infostr = localStorage.getItem('info');
    const info = JSON.parse(infostr);
    this.userInfo = info;
    // console.log(this.userInfo);
  }
}
