import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { DeliveryboyService } from '../../Shared/Services/deliveryboy.service';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { DeleviryBoyDeleteDialogComponent } from './deleviry-boy-delete-dialog/deleviry-boy-delete-dialog.component';
import { IDb, ICity, ISalaryGrad } from '../../models';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { CityService } from '../../Shared/Services/city.service';
import { SalaryGradService } from '../../Shared/Services/salaryGrad.service';
import { AreaListDialogComponent } from './area-list-dialog/area-list-dialog.component';

@Component({
  selector: 'ngx-delivery-boy',
  templateUrl: './delivery-boy.component.html',
  styleUrls: ['./delivery-boy.component.scss']
})
export class DeliveryBoyComponent implements OnInit {

  deliveryBoyForm: FormGroup;
  title: string;
  isdisable = false;
  data: any;
  dbList = [];
  salarysList: any[];

  copydbList = [];
  file: string | Blob;
  CityList: any;
  @ViewChild('deliveryboyitem', { static: false }) accordion;

  submitted = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  areaListByCity: ICity[];
  dbId = 0;
  imgPreview = '';
  DeliveryBoyAutoGenNo: string;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };



  constructor(private fb: FormBuilder,
    private salaryGradService: SalaryGradService,
    private deliveryboyService: DeliveryboyService,
    private cityService: CityService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {
    this.deliveryBoyForm = this.fb.group({
      dbId: [''],

      cityId: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobile1: ['', [Validators.required]],
      mobile2: [''],
      address: ['', [Validators.required]],
      areaId: ['', [Validators.required]],
      salaryGrade: ['', [Validators.required]],
      salaryId: [''],
      email: ['', [Validators.required, Validators.email]],
      joinDate: ['', [Validators.required]],
      active: [true],

      ifsc: ['', [Validators.required]],
      bankAcc: ['', [Validators.required]],
      branchName: ['', [Validators.required]],
      bankName: ['', [Validators.required]],



    });
  }



  ngOnInit() {
    this.getDeleviryBoyList();
    this.getCityList();
    this.getAreaListByCity;
    this.getSalaryGradList();
    this.fileInfo = '';

    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Delivery boy');
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = false;
		}

  }
  clearForm() {
    this.dbId = 0;
    this.deliveryBoyForm.reset();
    this.updateDBCode();
    this.submitted = false;
  }


  get f() {
    return this.deliveryBoyForm.controls;
  }
  getDate(dateinfo) {
    const day = dateinfo.getDate().toString();
    const year = dateinfo.getFullYear().toString();
    const month = (dateinfo.getMonth() + 1).toString();
    const dateStr = year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    return dateStr;
  }
  openDeleteDialog(subgrain: IDb) {
    this.dialogService.open(DeleviryBoyDeleteDialogComponent, {
      context: {
        title: '',
        data: subgrain
      },
    }).onClose.subscribe(() => {
      this.getDeleviryBoyList();
    });
  }

  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.uploadFile(this.file);
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }


  uploadFile(file) {
    const files = file;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.imgPreview = (fileReader.result).toString();
      // console.log(this.imgPreview);
    },
      fileReader.readAsDataURL(files);
  }


  validation() {
    this.submitted = true;
  }
  toggle() {
    this.accordion.toggle();
  }
  // to set valueto form
  setValueToForm(db) {
    // console.log(db);
    this.accordion.open();
    this.dbId = db.id;
    // this.deliveryBoyForm.controls.dbId.setValue(db.dbId);
    this.DeliveryBoyAutoGenNo = db.dbId;
    this.deliveryBoyForm.controls.firstName.setValue(db.firstName);
    this.deliveryBoyForm.controls.lastName.setValue(db.lastName);
    this.deliveryBoyForm.controls.mobile1.setValue(db.mobile1);
    this.deliveryBoyForm.controls.mobile2.setValue(db.mobile2);
    this.deliveryBoyForm.controls.address.setValue(db.address);
    this.deliveryBoyForm.controls.active.setValue(db.active);
    this.deliveryBoyForm.controls.email.setValue(db.email);
    this.deliveryBoyForm.controls.cityId.setValue(db.cityId);
    this.getAreaListByCity(db.cityId);
    this.deliveryBoyForm.controls.areaId.setValue(db.areaId);
    this.deliveryBoyForm.controls.joinDate.setValue(new Date(db.joiningDate));
    this.deliveryBoyForm.controls.salaryGrade.setValue(db.salaryId);

    this.deliveryBoyForm.controls.bankName.setValue(db.bankName);
    this.deliveryBoyForm.controls.ifsc.setValue(db.ifscCode);
    this.deliveryBoyForm.controls.bankAcc.setValue(db.accountNo);
    this.deliveryBoyForm.controls.branchName.setValue(db.branchName);

    this.imgPreview = db.imagePath;
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.dbList = this.copydbList.filter(item => {
        const code = String(item.dbId).toLocaleLowerCase();
        const fName = String(item.firstName).toLocaleLowerCase();
        const lName = String(item.lastName).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (code.includes(searchStr) || (fName.includes(searchStr) || lName.includes(searchStr)));
      });
    } else {
      this.dbList = this.copydbList;
    }
  }


  //  get DB list
  getDeleviryBoyList() {
    const url = `${environment.API_URL}/api/DeliveryBoy/GetDeliveryBoyList?pageSize=0&pageNumber=0`;
    this.deliveryboyService.getDeliveryBoyList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.dbList = response.data;
          this.copydbList = response.data;
          this.config.totalItems = this.dbList.length;
          this.updateDBCode();
        }
      });
  }



  addDeleviryBoy() {
    this.submitted = true;
    const data: IDb = {
      id: this.dbId,
      salaryId: +this.deliveryBoyForm.value.salaryGrade,
      dbId: this.DeliveryBoyAutoGenNo,
      joiningDate: this.getDate(this.deliveryBoyForm.value.joinDate),
      firstName: this.deliveryBoyForm.value.firstName,
      lastName: this.deliveryBoyForm.value.lastName,
      address: this.deliveryBoyForm.value.address,
      mobile1: this.deliveryBoyForm.value.mobile1,
      mobile2: this.deliveryBoyForm.value.mobile2,
      email: this.deliveryBoyForm.value.email,
      active: this.deliveryBoyForm.value.active,
      areaId: +this.deliveryBoyForm.value.areaId,
      imageBase64: this.imgPreview !== '' ? this.imgPreview.split(',')[1] : '',
      imagePath: '',

      bankName: this.deliveryBoyForm.value.bankName,
      ifscCode: this.deliveryBoyForm.value.ifsc,
      accountNo: this.deliveryBoyForm.value.bankAcc,
      branchName: this.deliveryBoyForm.value.branchName,



    };

    let url = null;
    if (this.deliveryBoyForm.valid) {
      this.isdisable = true;
      if (this.dbId === 0) {
        url = `${environment.API_URL}/api/DeliveryBoy/AddDeliveryBoy`;
      } else {
        url = `${environment.API_URL}/api/DeliveryBoy/UpdateDeliveryBoy`;
      }
      this.deliveryboyService.modifyDeliveryBoy(url, data).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.getDeleviryBoyList();
            if (this.dbId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.dbId = 0;
            this.submitted = false;
            this.deliveryBoyForm.reset();
            this.getDeleviryBoyList();
            this.fileInfo = '';
            this.isdisable = false;
            this.accordion.close();
          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isdisable = true;

          }
        });
    }
  }
  // get city list
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
        }
      });
  }
  // get Area by City list
  getAreaListByCity(event) {
    const url = `${environment.API_URL}/api/Area/GetAreaListByCityId?CityId=${+event}`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListByCity = [];
          this.areaListByCity = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListByCity = [];
        }
      });
  }

  // get salary grad  list
  getSalaryGradList() {
    const url = `${environment.API_URL}/api/Grade/GetGradeList`;
    this.salaryGradService.getSalaryList(url).subscribe
      ((response: ResponseModel<ISalaryGrad[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.salarysList = response.data;
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

  updateDBCode() {
    if (this.dbId === 0) {
      if (this.dbList) {
        if (this.dbList.length > 0) {
          this.DeliveryBoyAutoGenNo = 'DB-0' + (this.dbList.length + 1).toString();
        } else if (this.dbList == null || this.dbList.length === 0) {
          this.DeliveryBoyAutoGenNo = 'DB-01';
        }
      }
    }
  }

  // open pop for order assing area
  openPopUpForAssingArea(selectedDbId) {
    this.dialogService.open(AreaListDialogComponent, {
      context: {
        title: '',
        dbid: selectedDbId,
      },

    }).onClose.subscribe(() => {
      // this.getPassOrderListGP();
    });

  }
}
