import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.scss']
})
export class PrintBillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
