import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../responsemodel';

@Injectable({
    providedIn: 'root'
})
export class DocService {

    constructor(private http: HttpClient) { }


    noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };

    getDocList(url): Observable<any> {
        return this.http.get(url).pipe(map((response: Response) => response as any));
    }

    get(url: string): Observable<any> {
        return this.http.get(url)
            .pipe(map((response: ResponseModel<any>) => {
                return response;
            }));
    }

    modifyDoc(url, docInfo): Observable<any> {
        return this.http.post(url, docInfo, this.noContentType).pipe(map((response: Response) => response as any));
    }

    deleteDoc(url, docInfo): Observable<any> {
        return this.http.post(url, docInfo).pipe(map((response: Response) => response as any));
    }

    // deleteDoc(url): Observable<any> {
    //     return this.http.get(url).pipe(map((response: Response) => response as any));
    //   }
    // }
}
