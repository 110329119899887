import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrainComponent } from './grain/grain.component';
import { FPMasterRoutingModule } from './fpmaster-routing.module';
import { FPmastersComponent } from './fpmasters.component';
import { ThemeModule } from '../@theme/theme.module';
import {
	NbMenuModule,
	NbInputModule,
	NbCardModule,
	NbButtonModule,
	NbActionsModule,
	NbUserModule,
	NbCheckboxModule,
	NbRadioModule,
	NbDatepickerModule,
	NbSelectModule,
	NbIconModule,
	NbAccordionModule,
	NbDialogModule,
	NbToastrModule,
	NbToggleModule,
	NbTooltipModule,
} from '@nebular/theme';

import { AreaComponent } from './area/area.component';
import { CityComponent } from './city/city.component';
import { SubGrainComponent } from './sub-grain/sub-grain.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VendorFormComponent } from './vendor-form/vendor-form.component';
import { EmployeeComponent } from './employee/employee.component';
import { SubgrainDeleteDialogComponent } from './sub-grain/subgrain-delete-dialog/subgrain-delete-dialog.component';
import { GrainDeleteDialogComponent } from './grain/grain-delete-dialog/grain-delete-dialog.component';
import { AreaDeleteDialogComponent } from './area/area-delete-dialog/area-delete-dialog.component';
import { CityDeleteDialogComponent } from './city/city-delete-dialog/city-delete-dialog.component';
import { VendorDeleteDialogComponent } from './vendor-form/vendor-delete-dialog/vendor-delete-dialog.component';
import { AreaRateBoardComponent } from './area-rate-board/area-rate-board.component';
import { BpRateBoardComponent } from './bp-rate-board/bp-rate-board.component';
import { EmployeeDeleteDialogComponent } from './employee/employee-delete-dialog/employee-delete-dialog.component';
import { DeliverySlotComponent } from './delivery-slot/delivery-slot.component';
import { GpWeeklyHolidayComponent } from './gp-weekly-holiday/gp-weekly-holiday.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GpStockCapacityComponent } from './gp-stock-capacity/gp-stock-capacity.component';
import { DocumentComponent } from './employee/document/document.component';
import { SalaryGradComponent } from './salary-grad/salary-grad.component';
import { SettingComponent } from './setting/setting.component';
import { SlotDeleteDialogComponent } from './delivery-slot/slot-delete-dialog/slot-delete-dialog.component';
import { DocComponent } from './doc/doc.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RecipeComponent } from './recipe/recipe.component';
import { RatingComponent } from './rating/rating.component';
import { ReviewComponent } from './review/review.component';
import { RecipePopUpComponent } from './recipe/recipe-pop-up/recipe-pop-up.component';
import { HelpComponent } from './help/help.component';
import { ReportComponent } from './report/report.component';
import { ReportPopupComponent } from './report/report-popup/report-popup.component';
import { PromocodeComponent } from './promocode/promocode.component';
@NgModule({
	declarations: [
		GrainComponent, FPmastersComponent,
		AreaComponent, CityComponent, SubGrainComponent,
		VendorFormComponent, EmployeeComponent,
		SubgrainDeleteDialogComponent, AreaDeleteDialogComponent,
		CityDeleteDialogComponent, VendorDeleteDialogComponent,
		DeliverySlotComponent, GpWeeklyHolidayComponent,
		EmployeeDeleteDialogComponent, AreaRateBoardComponent,
		BpRateBoardComponent, GrainDeleteDialogComponent,
		GpStockCapacityComponent, DocumentComponent,
		SalaryGradComponent, SettingComponent, SlotDeleteDialogComponent,
		RecipePopUpComponent,
		ReportPopupComponent,
		DocComponent, RecipeComponent, RatingComponent, ReviewComponent,
		RecipePopUpComponent, HelpComponent, ReportComponent, ReportPopupComponent, PromocodeComponent
	],
	imports: [
		CommonModule,
		FPMasterRoutingModule,
		NbMenuModule,
		ThemeModule,
		NbInputModule,
		NbCardModule,
		NbButtonModule,
		NbActionsModule,
		NbUserModule,
		NbCheckboxModule,
		NbRadioModule,
		NbDatepickerModule,
		NbSelectModule,
		NbIconModule,
		FormsModule,
		ReactiveFormsModule,
		NbAccordionModule,
		NbDialogModule,
		NbToastrModule,
		NbToggleModule,
		NgxPaginationModule,
		NbTooltipModule,
		Ng2SmartTableModule,
		NbTooltipModule
	],
	// providers: [GrainService, AreaService, CityService, VendorService],
	entryComponents: [SubgrainDeleteDialogComponent,
		DocumentComponent,
		AreaDeleteDialogComponent,
		CityDeleteDialogComponent,
		VendorDeleteDialogComponent,
		EmployeeDeleteDialogComponent,
		GrainDeleteDialogComponent,
		SlotDeleteDialogComponent,
		RecipePopUpComponent,
		ReportPopupComponent
	]
})
export class FPMastersModule { }
