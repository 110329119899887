import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'ngx-pass-order',
  templateUrl: './pass-order.component.html',
  styleUrls: ['./pass-order.component.scss']
})
export class PassOrderComponent implements OnInit {

  orderList = [];
  tempOrderList = [];
  constructor() {

  }

  ngOnInit() {
    for (let i = 0; i < 200; i++) {
      const order = {
        OrderId: `ORD${this.getRandomIntInclusive()}`,
        Price: this.getRandomPrice(),
        Date: this.randomDate()
      };

      this.tempOrderList.push(order);
      this.orderList.push(order);
    }

  }

  getRandomIntInclusive(min = 100, max = 100000) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomPrice(min = 30, max = 200) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  randomDate(start = new Date(2012, 0, 1), end = new Date()) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toUTCString();
  }

  filterSearch(searchtxt: string) {
    this.orderList = this.tempOrderList.filter((order) => {
      return (String(order.OrderId).toLowerCase()).includes(searchtxt.trim());
    });
  }

}
