import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment/payment.component';

import {
	NbMenuModule,
	NbInputModule,
	NbCardModule,
	NbButtonModule,
	NbActionsModule,
	NbUserModule,
	NbCheckboxModule,
	NbRadioModule,
	NbDatepickerModule,
	NbSelectModule,
	NbIconModule,
	NbAccordionModule,
	NbDialogModule,
	NbToastrModule,
	NbToggleModule,
	NbTooltipModule,
	NbTabsetModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaymentRoutingModule } from './payment.routing.module';
import { PopUpComponent } from './pop-up/pop-up.component';
import { RelaseBpPaymentComponent } from './relase-bp-payment/relase-bp-payment.component';
import { BpPaymentOrderWiseComponent } from './bp-payment-order-wise/bp-payment-order-wise.component';
import { DbPaymentOrderWiseComponent } from './db-payment-order-wise/db-payment-order-wise.component';
import { UpdateStatusComponent } from './relase-bp-payment/update-status/update-status.component';
import { ReleaseDbPaymentComponent } from './release-db-payment/release-db-payment.component';
import { UpdateDbStatusComponent } from './release-db-payment/update-db-status/update-db-status.component';
import { ViewDbDetailsComponent } from './release-db-payment/view-db-details/view-db-details.component';
import { ViewBpDetailsComponent } from './relase-bp-payment/view-bp-details/view-bp-details.component';

@NgModule({
	declarations: [
		PaymentComponent,
		DbPaymentOrderWiseComponent,
		RelaseBpPaymentComponent,
		BpPaymentOrderWiseComponent,
		PopUpComponent,
		UpdateStatusComponent,
		ReleaseDbPaymentComponent,
		UpdateDbStatusComponent,
		ViewDbDetailsComponent,
		ViewBpDetailsComponent,
	],

	imports: [
		PaymentRoutingModule,
		CommonModule,
		NbMenuModule,
		ThemeModule,
		NbInputModule,
		NbCardModule,
		NbButtonModule,
		NbActionsModule,
		NbUserModule,
		NbCheckboxModule,
		NbRadioModule,
		NbDatepickerModule,
		NbSelectModule,
		NbIconModule,
		FormsModule,
		ReactiveFormsModule,
		NbAccordionModule,
		NbDialogModule,
		NbToastrModule,
		NbToggleModule,
		NgxPaginationModule,
		NbTooltipModule,
		NbTabsetModule,
	],
	providers: [],

	entryComponents: [
		PopUpComponent,
		UpdateStatusComponent,
		UpdateDbStatusComponent,
		ViewDbDetailsComponent,
		ViewBpDetailsComponent,
	],
})
export class PaymentModule {}
