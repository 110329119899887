import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	noContentType = { headers: new HttpHeaders({ noContentType: 'True' }) };

	constructor(private http: HttpClient) {}

	addBanner(url, data): Observable<any> {
		return this.http
			.post(url, data, this.noContentType)
			.pipe(map((response: Response) => response as any));
	}

	getBannerList(url: string): Observable<any> {
		return this.http.get(url);
	}
}
