import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { IWarehouse } from '../../../models';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { ReadymadeService } from '../../../Shared/Services/readymade.service';

@Component({
  selector: 'ngx-warehouse-stock',
  templateUrl: './warehouse-stock.component.html',
  styleUrls: ['./warehouse-stock.component.scss']
})
export class WarehouseStockComponent implements OnInit {
  warwhouseStockForm: FormGroup;
  warehouseList = [];
  stockList = [];
  copyWarehouseList = [];
  stockListGP: IWarehouse[];

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private readyMadeStockService: ReadymadeService
  ) { }

  ngOnInit() {
    this.getWarehouseList();
  }



  getWarehouseList() {
    const url = `${environment.API_URL}/api/Warehouse/GetWarehouseList?Pagesize=0&PageNo=0`;
    this.readyMadeStockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.warehouseList = response.data.warehouses;
          this.copyWarehouseList = response.data.warehouses;
          this.config.totalItems = this.warehouseList.length;
        } else {
          this.warehouseList = [];
        }
      }
    );
  }

  getStockList(event) {
    const url = `${environment.API_URL}/api/ReadyMateStock/ReadymateStock?warehouseId=${+event.target.value}`;
    this.readyMadeStockService
      .getStockList(url)
      .subscribe((response: ResponseModel<IWarehouse[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.stockListGP = response.data;
        } if (response.status === 204) {
          this.stockListGP = null;

        }
      });
  }

}
