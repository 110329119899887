import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { CityService } from '../../../Shared/Services/city.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { IOrderDetails } from '../../../models/order.model';


@Component({
  selector: 'ngx-assing-order-db',
  templateUrl: './assing-order-db.component.html',
  styleUrls: ['./assing-order-db.component.scss']
})
export class AssingOrderDbComponent implements OnInit {

  dbList: any;
  title: string;
  data: IOrderDetails;

  details: any[];
  name: any;

  selectedGpId = 0;
  disableBtnAfterAssing = false;
  cityList: any[];
  areaList: any[];

  areaCodeWiseGPList: any[];
  areaWiseDBList: any[];

  assingOrderForm: FormGroup;

  constructor(
    private cityService: CityService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,

    private dialogRef: NbDialogRef<any>
  ) {
    this.assingOrderForm = this.fb.group({
      gpId: +this.selectedGpId,
      city: ['', [Validators.required]],
      area: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getCityList();
  }

  close() {
    this.dialogRef.close();
  }

  get f() {
    return this.assingOrderForm.controls;
  }

  // for getting city
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.cityList = response.data;
        }
      });
  }

  // get area from city
  getAreaListByCity(event) {
    const url = `${
      environment.API_URL
      }/api/Area/GetAreaListByCityId?CityId=${+event}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaList = [];
        }
      });
  }

  // get areaCodeWise GP List
  getAreaCodeWiseGpList(event) {
    const url = `${
      environment.API_URL
      }/api/GPartner/GetGpartnerbyAreaId?AreaId=${+event}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaCodeWiseGPList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaCodeWiseGPList = [];
        }
      });
  }

  // get area wise DB list
  getAreaWiseDBList(event) {
    const url = `${
      environment.API_URL
      }/api/DeliveryBoy/AreawiseDB?AreaId=${+event}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaWiseDBList = response.data;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaWiseDBList = [];
        }
      });
  }

  // get area wise DB list
  getDbList(event) {
    const url = `${
      environment.API_URL
      }/api/DeliveryBoy/AreawiseDB?AreaId=${+event}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.dbList = response.data;
          this.details = this.dbList;
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.dbList = '';
        }
      });
  }

  // assing order to DB
  assingOrdertOdB(selectedDbId, selectedOrderId) {
    this.disableBtnAfterAssing = true;
    const url = `${environment.API_URL}/api/DeliveryBoyOrder/AssignOrderDeliveryBoy?DbId=${selectedDbId}&OrderId=${selectedOrderId}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.disableBtnAfterAssing = true;
          this.showToast(
            'top-right',
            'success',
            'Order  Successfully Assingned'
          );
          this.notificationForDelivery();
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(status || 'Success', `${message}`, {
      position,
      status,
    });
  }

  getclass(ele) {
    const min = (ele.capacityInKg * ele.minPercentage) / 100;
    const max = ele.capacityInKg;
    return ele.stock <= min ? 'min-limit' : ele.stock > max ? 'max-limit' : '';
  }

  notificationForDelivery() {
    const url = `${environment.API_URL}/api/DeliveryBoyOrder/NotificationForAssignDPartnerOrder?OrderId=
    ${this.data['orderDetails'].id}`;
    this.cityService
      .getCityList(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
        }
      });
  }


}
