import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	constructor(private http: HttpClient) {}

	private isProductUpdate = new BehaviorSubject(false);
	getProductUpdateStatus = this.isProductUpdate.asObservable();

	currentProductStatus(flag) {
		this.isProductUpdate.next(flag);
	}

	getProductList(url): Observable<any> {
		return this.http
			.get(url)
			.pipe(map((response: Response) => response as any));
	}

	modifyProduct(url, productInfo): Observable<any> {
		return this.http
			.post(url, productInfo)
			.pipe(map((response: Response) => response as any));
	}
}
