import { Component, OnInit } from '@angular/core';
import { IStock } from '../../../models';
// import { StockService } from '../../../Shared/Services/stock.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
// import { environment } from '../../../../environments/environment';
// import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
  selector: 'ngx-stockin-delete-dialog',
  templateUrl: './stockin-delete-dialog.component.html',
  styleUrls: ['./stockin-delete-dialog.component.scss']
})
export class StockinDeleteDialogComponent implements OnInit {

  title: string;
  data: IStock;
  constructor(private dialogRef: NbDialogRef<any>,
    // private stockService: StockService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }



  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
