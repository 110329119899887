import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGrain, ICity } from '../../models';
import { GpartnerService } from '../../Shared/Services/gpartner.service';

@Component({
	selector: 'ngx-gpartner-stock',
	templateUrl: './gpartner-stock.component.html',
	styleUrls: ['./gpartner-stock.component.scss'],
})
export class GpartnerStockComponent implements OnInit {
	submitted = false;
	stockListGP: IGrain[];
	gpList: any[];
	gpId = 0;
	inTransitList: ICity[];

	pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

	constructor(
		private gpartnerService: GpartnerService
	) { }
	ngOnInit() {
		const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page;
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = true;
		}
		this.getGPList();
	}

	// get Grain Partner list
	getGPList() {
		const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
		this.gpartnerService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<ICity[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.gpList = response.data;
				}
			});
	}

	changeTag(event) {
		if (event.tabId === 'active') {
		  this.getGrainPartnerStockList(event);
		} else {
		  this.getTransitStock(event);
		}
	  }

	// get Grain Partner Stock list
	getGrainPartnerStockList(event) {
		const url = `${environment.API_URL}/api/Stock/Company/GpartrnerStockList?GPId=${+event.target.value}`;
		this.gpartnerService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<IGrain[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.gpId = +event.target.value;
					this.stockListGP = response.data;
					// this.getTransitStock(event);
				} if (response.status === 204) {
					this.stockListGP = null;

				}
			});
	}
	// get In Transit stock list
	getTransitStock(event) {
		const url = `${
			environment.API_URL
			}/api/Stock/IntransistStockList?GPId=${+event.target.value}`;
		this.gpartnerService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<ICity[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.inTransitList = response.data;
					// this.stockListGP = [] ;
				// this.getGrainPartnerStockList(event);
				} if (response.status === 204) {
					this.inTransitList = null;
				}
			});
	}

	getclass(ele) {
		const min = (ele.capacityInKg * ele.minPercentage) / 100;
		const max = ele.capacityInKg;
		return ele.stock <= min ? 'min-limit' : ele.stock > max ? 'max-limit' : '';
	}

	downloadInvoice() {
		const url = `${environment.API_URL}/api/Report/GPartnerStock?GPartnerId=${this.gpId}`;
		this.gpartnerService
			.getGPartnerList(url)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					window.open(response.data, '_blank');
				}
			});
	}
}
