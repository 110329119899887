import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './Shared/Services/auth.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('../app/auth/auth.module').then((m) => m.AuthModule),
	},

	{
		path: 'dashboard',
		loadChildren: () =>
		import('../app/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard], 
	},
	{
		path: 'masters',
		loadChildren: () =>
			import('../app/fpmasters/fpmasters.module').then(
				(m) => m.FPMastersModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'stock',
		loadChildren: () =>
			import('../app/stock/stock.module').then((m) => m.StockModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'care',
		loadChildren: () =>
			import('../app/care/care.module').then((m) => m.CareModule),
		canActivate: [AuthGuard],
	},


	{
		path: 'payment',
		loadChildren: () =>
			import('../app/payment/payment.module').then((m) => m.PaymentModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'product',
		loadChildren: () =>
			import('../app/product/product.module').then((m) => m.ProductModule),
		canActivate: [AuthGuard],
	},
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
	useHash: true,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
