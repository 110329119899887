import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { CityService } from '../../Shared/Services/city.service';
import { ICity } from '../../models/city.model';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { CityDeleteDialogComponent } from './city-delete-dialog/city-delete-dialog.component';

@Component({
  selector: 'ngx-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
})
export class CityComponent implements OnInit {
  cityForm: FormGroup;
  submitted = false;
  cityList = [];
  copyCityList = [];
  isUpdate = false;
  grainList: ICity[] = [];
  pageSize = 100;
  pageNumber = 1;
  isSaveDisabled = false;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  id = 0;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };
  @ViewChild('cityItem', { static: false }) accordion;
  constructor(private fb: FormBuilder,
    private cityService: CityService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) {

    this.cityForm = this.fb.group({
      cityNameEng: ['', [Validators.required]],
      cityNameMar: ['', [Validators.required]],
      cityNameHin: ['', [Validators.required]],
      isActive: [false]
    });
  }

  validation() {
    this.submitted = true;
  }

  ngOnInit() {
    this.getCityList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
     this.pageRole = role.page.find(m => m.page === 'City');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }


  }
  clearForm() {
    this.cityForm.reset();
  }
  get f() {
    return this.cityForm.controls;
  }
  addCity() {
    this.submitted = true;
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  filter(str: string) {

    str = str.trim();
    if (str !== '') {
      this.cityList = this.copyCityList.filter(item => {
        const name = String(item.cityNameEng).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.cityList = this.copyCityList;
    }
  }
  
  // get city list
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetALLCityList?pageSize=0&pageNumber=0`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.cityList = response.data;
          this.copyCityList = response.data;
          this.config.totalItems = this.cityList.length;
        }
      });
  }
  // for set value to w.r.s to feild
  setValueToForm(city) {
    this.accordion.open();
    this.id = city.id;
    this.isUpdate = true;
    this.cityForm.controls.cityNameEng.setValue(city.cityNameEng);
    this.cityForm.controls.cityNameMar.setValue(city.cityNameMar);
    this.cityForm.controls.cityNameHin.setValue(city.cityNameHin);
    this.cityForm.controls.isActive.setValue(city.isActive);

  }
  // add City
  addtionCity() {
    const data = {
      id: this.id,
      cityNameEng: this.cityForm.value.cityNameEng,
      cityNameMar: this.cityForm.value.cityNameMar,
      cityNameHin: this.cityForm.value.cityNameHin,
      isActive: this.cityForm.value.isActive,
    };

    if (this.cityForm.valid) {
      this.isSaveDisabled = true;
      let apiurl = '';
      if (this.id === 0) {
        apiurl = `${environment.API_URL}/api/City/AddCity`;
      } else {
        apiurl = `${environment.API_URL}/api/City/UpdateCity`;
      }
      this.cityService.modifyCity(apiurl, data).subscribe
        ((response: ResponseModel<ICity>) => {
          if (response.status === HttpStatusCode.OK) {
            this.isSaveDisabled = false;
            if (this.id === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.cityForm.reset();
            this.submitted = false;
            this.accordion.close();
            this.getCityList();
            this.id = 0;
            this.isUpdate = true;
          } else {
            if (response.status === 400) {
              this.showToast('top-right', 'danger', response.statusMessage);
              this.isSaveDisabled = false;
            }
            this.isSaveDisabled = false;
            this.id = 0;
            this.isUpdate = false;
          }
        });
    }
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
  openDeleteDialog(area: ICity) {
    this.accordion.close();
    this.dialogService.open(CityDeleteDialogComponent, {
      context: {
        title: '',
        data: area,
      },
    }).onClose.subscribe(() => {
      this.getCityList();
    });
  }
}
