import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders, IOrderDetails } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { StatusUpdateComponent } from '../../status-update/status-update.component';

@Component({
  selector: 'ngx-order-in-progress',
  templateUrl: './order-in-progress.component.html',
  styleUrls: ['./order-in-progress.component.scss']
})
export class OrderInProgressComponent implements OnInit {

  public orderList = [];
  copyOrderList = [];

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }

  ngOnInit() {
    this.getOrderInProcess();

  }
  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 1,
        }
      },
    });
  }


  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }



  filter(str: string) {
    if (str.trim() !== '') {
      this.orderList = this.copyOrderList.filter(item => {
        const order = String(item.orderDetails.orderId).toLocaleLowerCase();
        const fName = String(item.userInfo.firstName).toLocaleLowerCase();
        const lName = String(item.userInfo.lastName).toLocaleLowerCase();
        const area = String(item.userInfo.area).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (order.includes(searchStr)) ||
          (fName.includes(searchStr)) || (lName.includes(searchStr)) || (area.includes(searchStr));
      });

    } else {
      this.orderList = this.copyOrderList;
    }
  }
  // get order list
  getOrderInProcess() {
    const url = `${environment.API_URL}/api/Order/GetOrdersFromAdmin?status=2&PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          this.config.totalItems = this.orderList.length;
        }
      });
  }
  statusUpdate(order: IOrderDetails) {
    this.dialogService.open(StatusUpdateComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 3,
        }
      },
    }).onClose.subscribe(() => {
      this.getOrderInProcess();
    });
  }
}
