import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders, IOrderDetails } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';

@Component({
  selector: 'ngx-pick-up-order',
  templateUrl: './pick-up-order.component.html',
  styleUrls: ['./pick-up-order.component.scss']
})
export class PickUpOrderComponent implements OnInit {
  public orderList = [];
  copyOrderList = [];

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }

  ngOnInit() {
    this.getPickUpOrderList();

  }
  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 8,
        }
      },
    });
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

  filter(str: string) {
    if (str.trim() !== '') {
      this.orderList = this.copyOrderList.filter(item => {
        const order = String(item.orderDetails.orderId).toLocaleLowerCase();
        const fName = String(item.userInfo.firstName).toLocaleLowerCase();
        const lName = String(item.userInfo.lastName).toLocaleLowerCase();
        const area = String(item.userInfo.area).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (order.includes(searchStr)) || (fName.includes(searchStr))
          || (lName.includes(searchStr)) || (area.includes(searchStr));
      });

    } else {
      this.orderList = this.copyOrderList;
    }
  }
  // get  pick up order list
  getPickUpOrderList() {
    const url = `${environment.API_URL}/api/Order/GetPickedUpOrderAdmin?PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          this.config.totalItems = this.orderList.length;
        }
      });
  }

}
