import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'ngx-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  title1: string;
  data: any;
  file: File;
  pageRole: any;
  roleId: number;
  saveBtnHide = true ;

  constructor() { }

  ngOnInit() {
  }
  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }
  items: NbMenuItem[] = [
    {
      title: 'Menu link with parameters',
      expanded: true,
      children: [
        {
          title: 'Goes into angular `routerLink`',
          link: '', // goes into angular `routerLink`
        },
      ]
    }
  ];
}
