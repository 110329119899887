import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IAreaRateBoard, IRateBoard } from '../../models/areaRateBoard.model';
import { AreaRateBoardService } from '../../Shared/Services/areaRateBoard';
import { ICity } from '../../models';
import { CityService } from '../../Shared/Services/city.service';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-area-rate-board',
  templateUrl: './area-rate-board.component.html',
  styleUrls: ['./area-rate-board.component.scss']
})
export class AreaRateBoardComponent implements OnInit {
  // AreaRAteBoardForm: FormGroup;
  AreaRateBoardList: IRateBoard[] = [];
  @ViewChild('areaRAteBoard', { static: false }) accordion;

  CityList = [];
  areaListByCity = [];
  areaId = 0;
  isSaveDisabled = false;
  selectedAreaId = '0';
  selectedCityId = '0';
  selectedAreaNameEng = '0';

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  constructor(
    private activatedroute: ActivatedRoute,
    private cityService: CityService,
    private areaRateBoardService: AreaRateBoardService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Area rate board');
    // this.pageRole = role.page;
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }


    this.selectedCityId = this.activatedroute.snapshot.paramMap.get('cityid');
    this.selectedAreaId = this.activatedroute.snapshot.paramMap.get('areaid'); // areaId
    this.selectedAreaNameEng = this.activatedroute.snapshot.paramMap.get('areaNameEng');


    this.getCityList();
    this.getAreaListByCity(this.selectedCityId);
    this.getAreaRateBoardList(this.selectedAreaId);
  }

  getAreaRateBoardList(areaId) {
    const url = `${environment.API_URL}/api/AreaRateBoard/GetAreaRateBoardList?areaId=${areaId}`;
    this.areaRateBoardService.getAreaRateBoardList(url).subscribe(
      (response: ResponseModel<IRateBoard[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaId = +areaId;
          this.AreaRateBoardList = response.data['grains'];
        }
      });
  }
  changeArea(event) {
    const selectedAreaCode = event.target.value;
    this.getAreaRateBoardList(selectedAreaCode);
  }


  changeCity(event) {
    const cityid = event.target.value;
    this.getAreaListByCity(cityid);
  }

  // get city list
  getCityList() {
    const url = `${environment.API_URL}/api/City/GetCityList?pageSize=0&pageNumber=0`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.CityList = response.data;
          this.areaListByCity = [];
        }
      });
  }
  // get Area by City list
  getAreaListByCity(cityid: string) {
    const url = `${environment.API_URL}/api/Area/GetAreaListByCityId?CityId=${cityid}`;
    this.cityService.getCityList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.areaListByCity = [];
          this.areaListByCity = response.data;
          this.getAreaRateBoardList('0');
        } else if (response.status === HttpStatusCode.NO_CONTENT) {
          this.areaListByCity = [];
        }
      });
  }


  saveRate() {
    this.isSaveDisabled = true;
    const formData: IAreaRateBoard = {
      areaCode: '',
      areaId: this.areaId,
      areaName: '',
      city: '',
      cityId: 0,
      grains: this.AreaRateBoardList,
    };

    const url = `${environment.API_URL}/api/AreaRateBoard/SaveAreaRateBoard`;
    this.areaRateBoardService.modifyAreaRateBoard(url, formData).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Save Successfully');
          // this.accordion.close();

        }
        this.isSaveDisabled = true;
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
