import { Component, OnInit } from '@angular/core';

import { MENU_ITEMS} from '../fp-menu-item';
import { MenuService } from '../Shared/Services/menu.service';

@Component({
  selector: 'ngx-masters',
  styleUrls: ['fpmasters.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class FPmastersComponent implements OnInit {
  menu = MENU_ITEMS;

  constructor(
  private  menuService: MenuService
  ) { }

  ngOnInit() {
    this.menu =   this.menuService.setMenu();
  }
}
