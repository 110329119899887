import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GpartnerService {
  employee(): import('../../models').IGPartner[] {
	  throw new Error('Method not implemented.');
  }
  getProducts(): import('../../models').IGPartner[] {
	  throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  getGPartnerList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }


  modifyGPartner(url, gPartnerInfo): Observable<any> {
      return this.http.post(url, gPartnerInfo).pipe(map((response: Response) => response as any));
  }
}
