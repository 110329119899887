import { Component, OnInit } from '@angular/core';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';
import { IVendor } from '../../../models/vendor.model';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { VendorService } from '../../../Shared/Services/vendor.service';

@Component({
  selector: 'ngx-vendor-delete-dialog',
  templateUrl: './vendor-delete-dialog.component.html',
  styleUrls: ['./vendor-delete-dialog.component.scss']
})
export class VendorDeleteDialogComponent implements OnInit {
  constructor(private vendorService: VendorService,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService) { }
    title: string;
    data: IVendor;
  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
  // for delete Vendor
  deleteVendor() {
    const url = `${environment.API_URL}/api/Vendor/DeleteVendor?id=${this.data.id}`;
    this.vendorService.getVendorList(url).subscribe
          ((response: ResponseModel<IVendor[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Deleted Successfully');
          this.close();
        } else {
          this.showToast('top-right', 'danger', response.statusMessage);
        }
      });
  }
  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
