import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryboyService {

  constructor(private http: HttpClient) { }

  getDeliveryBoyList(url): Observable<any> {
    return this.http.get(url).pipe(map((response: Response) => response as any));
  }


  modifyDeliveryBoy(url, devliverBoyInfo): Observable<any> {
      return this.http.post(url, devliverBoyInfo).pipe(map((response: Response) => response as any));
  }
}
