import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { StockService } from '../../../Shared/Services/stock.service';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-stockout-delete-dialog',
  templateUrl: './stockout-delete-dialog.component.html',
  styleUrls: ['./stockout-delete-dialog.component.scss']
})
export class StockoutDeleteDialogComponent implements OnInit {

  title: string;
  data: any;
  constructor(private dialogRef: NbDialogRef<any>,
    private stockService: StockService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }



  deleteStockOut() {
    const url = `${environment.API_URL}/api/StockOut/DeleteGPartnerStock?stockInId=${this.data.id}`;
    this.stockService.getStockList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }


  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
