import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Promocode } from '../../models/promocode.model';
import { PromocodeService } from '../../Services/promocode.service';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { NbToastrService, NbDialogService } from '@nebular/theme';


@Component({
  selector: 'ngx-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.scss']
})

export class PromocodeComponent implements OnInit {
  PromocodeFormArray: FormArray = this.fb.array([]);
  promocodeForm: FormGroup;
  submitted = false;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;
  promocodeId= 0;
  disabledBtn: boolean;
  file: any;
  fileInfo: string;
  pageSize: 0;
  pageNumber: 0;
  promocodeList = [];
  copyPromocodeList = [];

  @ViewChild('promocode', { static: false }) accordion;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private fb: FormBuilder,
		private promocodeService: PromocodeService,
    private toastrService: NbToastrService,
  ) 
  {
	  this.promocodeForm = this.fb.group({
	  promoCode: ['', [Validators.required]],
      walletAmt: ['',[Validators.required]],
      stratDate: ['',[Validators.required]],
      endDate: ['',[Validators.required]],
      remark: ['',[Validators.required]],
	  isActive: [false]
 });
	}


  ngOnInit() {

    this.getPromocodeList();
    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page.find(m => m.page === 'Promocode Form');
		if (this.pageRole.isWrite === false) {
			this.saveBtnHideForRole = false;
		}

		this.getPromocodeList(this.pageSize, this.pageNumber);
		this.promocodeService.getPromocodeUpdateStatus.subscribe(flag => {
			if (flag) {
				this.submitted = false;
				this.promocodeId = 0;
				this.getPromocodeList(0, 0);
			}
		});
  }

	get f() {
		return this.promocodeForm.controls;
	}


  savePromocode(){
		this.submitted = true;
		const data: Promocode = {
		id: this.promocodeId,
        promoCode: this.promocodeForm.value.promoCode,
		walletAmt: Number(this.promocodeForm.value.walletAmt),
        stratDate: this.promocodeForm.value.stratDate,
        endDate: this.promocodeForm.value.endDate,
		isActive: this.promocodeForm.value.isActive,
        remark: this.promocodeForm.value.remark,

		};
		if (this.promocodeForm.valid) {
			let apiurl = '';
     
			if (this.promocodeId === 0) {
				apiurl = `${environment.API_URL}/api/PromoCode/AddPromCode`;
			} else { apiurl = `${environment.API_URL}/api/PromoCode/UpdatePromoCode`; }

			this.disabledBtn = true;
			this.promocodeService.modifyPromocode(apiurl, data).subscribe((response: ResponseModel<any>) => {

				this.disabledBtn = false;
				if (response.status === HttpStatusCode.OK) {
					if (this.promocodeId === 0) {
						this.showToast('top-right', 'success', 'Added Successfully');
					} else {
						this.showToast('top-right', 'success', 'Updated Successfully');
					}
					this.clearForm();
					this.submitted = false;
					this.promocodeId = 0;
					this.file = null;
					this.fileInfo = '';
					this.getPromocodeList(0, 0);
					this.accordion.close();
				} else {
					this.showToast('top-right', 'danger', response.statusMessage);
				}
			});

		}
  }

  clearForm() {
    	this.submitted = false;
		this.promocodeForm.reset();
		this.promocodeForm.controls.type.setValue(1);
		this.fileInfo = '';
		this.disabledBtn = false;
		this.promocodeId = 0;
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }

	filter(str: string) {
		str = str.trim();
		if (str !== '') {
		  this.promocodeList = this.copyPromocodeList.filter(item => {
			const name = String(item.promoCode).toLocaleLowerCase();
			const city = String(item.cityNameEng).toLocaleLowerCase();
			const searchStr = String(str).toLowerCase();
			return (name.includes(searchStr) || city.includes(searchStr));
		  });
		} else {
		  this.promocodeList = this.copyPromocodeList;
		}
	  }


  getPromocodeList(pageSize: number = 10, pageNumber: number = 1) {
    const url = `${environment.API_URL}/api/PromoCode/GetPromoCodeList?pageSize=0&pageNumber=0`;
    this.promocodeService.getPromocodeList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.promocodeList = response.data;
          this.copyPromocodeList = response.data;
          this.config.totalItems = this.promocodeList.length;
        }
      });
  }

  editPromocode(promocode) {
	this.accordion.open();
	this.promocodeId = promocode.id;
	this.promocodeForm.controls.promoCode.setValue(promocode.promoCode);
	this.promocodeForm.controls.walletAmt.setValue(promocode.walletAmt);
	this.promocodeForm.controls.remark.setValue(promocode.remark);
	this.promocodeForm.controls.stratDate.setValue(promocode.stratDate);
	this.promocodeForm.controls.endDate.setValue(promocode.endDate);
	this.promocodeForm.controls.isActive.setValue(promocode.isActive);
	this.PromocodeFormArray = this.fb.array([]);
}

}
