import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor( ) {}

    req: HttpRequest<any>;

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        if (request.headers.get('noContentType')) {
            this.req = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('info')).token}`
                }
            });
        } else {
            this.req = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('info')).token}`
                }
            });
        }

        return next.handle(this.req);
    }
}
