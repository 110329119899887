import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { ComplainService } from '../../Shared/Services/complain.service';
import { IComplain } from '../../models/complain.model';

import { RefundComponent } from '../refund/refund.component';
import { TicketDialogComponent } from './dialog/ticket-dialog.component';

@Component({
  selector: 'ngx-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  statusZero: any[];
  statusOne: any[];
  statusTwo: any[];
  statusThree: any[];


  openList: any[];
  copyopenList: any[];

  deActiveopenList: any[];
  notOpenList: any[];
  copyNotOpenList: any[];
  productDetails: any[];


  user = 0;
  userId = 0;
  activeDeactiveBtn = false;

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;


  config = {
    itemsPerPage: 500,
    currentPage: 1,
    totalItems: 0
  };


  constructor(
    private toastrService: NbToastrService,
    private complainService: ComplainService,
    private dialogService: NbDialogService

  ) { }

  ngOnInit() {
    this.newComplain();
    this.inProesss();
    this.reSolve();
    this.close();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
     this.pageRole = role.page.find(m => m.page === 'Add tickets');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }
  pageChange1(pageNo) {
    this.config.currentPage = pageNo;
  }
  openAssingDialog(comp: IComplain) {
    this.dialogService.open(TicketDialogComponent, {
      context: {
        title: '',
        data: comp,
      }
    })
      .onClose.subscribe(() => {
        this.newComplain();
        this.inProesss();
        this.reSolve();
        this.close();
      });
  }



  filter1(str: string) {
    str = str.trim();
    if (str !== '') {
      this.openList = this.copyopenList.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();

        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) ||
          (orderNo.includes(searchStr));
      });
    } else {
      this.openList = this.copyopenList;
    }
  }


  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.notOpenList = this.copyNotOpenList.filter(item => {
        const name = String(item.firstName + ' ' + item.lastName).toLocaleLowerCase();
        const complaintCode = String(item.complaintCode).toLocaleLowerCase();
        const orderNo = String(item.orderNo).toLocaleLowerCase();


        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr)) || (complaintCode.includes(searchStr)) || (orderNo.includes(searchStr));
      });
    } else {
      this.notOpenList = this.copyNotOpenList;
    }
  }
  // status wise order complaint list
  newComplain() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=0&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusZero = response.data;
      } else {
        this.statusZero = [];
      }
    });
  }

  inProesss() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=1&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusOne = response.data;
      } else {
        this.statusOne = [];
      }
    });
  }

  reSolve() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=2&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusTwo = response.data;
      } else {
        this.statusTwo = [];
      }
    });
  }

  close() {
    const url = `${environment.API_URL}/api/Complaint/GetComplaintsByStatus?status=3&PageSize=0&PageNo=0`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.statusThree = response.data;
      } else {
        this.statusThree = [];
      }
    });
  }


  refundPopUp(statusThree: IComplain) {
    this.dialogService.open(RefundComponent, {
      context: {
        title: '',
        data: statusThree,
      },

    })
    .onClose.subscribe(() => {
      this.newComplain();
      this.inProesss();
      this.reSolve();
      this.close();
    });
  }

  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }
}
