import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../Shared/Services/product.service';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { IProduct } from '../../../models/product.model';

@Component({
  selector: 'ngx-product-delete-dialog',
  templateUrl: './product-delete-dialog.component.html',
  styleUrls: ['./product-delete-dialog.component.scss']
})
export class ProductDeleteDialogComponent implements OnInit {

  title: string;
  data: IProduct;
  constructor(
    private dialogRef: NbDialogRef<any>,
     private productService: ProductService,
     private toastrService: NbToastrService) { }

  ngOnInit() {
   }

  close() {
    this.dialogRef.close();
  }

  deleteProduct() {
    const url = `${environment.API_URL}/api/Product/Delete?ProductId=${this.data.id}`;
    this.productService.getProductList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
