import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IRateBoard } from '../../models/areaRateBoard.model';
import { ICity } from '../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GpartnerService } from '../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-bp-payment-order-wise',
  templateUrl: './bp-payment-order-wise.component.html',
  styleUrls: ['./bp-payment-order-wise.component.scss']
})
export class BpPaymentOrderWiseComponent implements OnInit {

  bpOrderForm: FormGroup;
  gpRateBoardList: IRateBoard[] = [];
  @ViewChild('GPrateBoard', { static: false }) accordion;

  dbList: any[];
  gpList: any[];
  emptyList = [];
  selectedGpId = 0;
  disabeldTable = false;



  dataFormat = [
    //  { value: '0', label: 'Defult' },
    { value: '1', label: 'Today' },
    { value: '2', label: 'Lastweeek' },
    { value: '3', label: 'LastMonth' },
    { value: '4', label: 'TillDate' },
  ];
  date;


  constructor(
    private fb: FormBuilder,
    private gpartnerService: GpartnerService,
  ) {

    this.bpOrderForm = this.fb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],

    });
  }

  ngOnInit() {
    this.getGPList();
  }

  get f() {
    return this.bpOrderForm.controls;
  }


  getGPList() {
    const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.gpList = response.data;
        }
      });
  }

  gpOnChange(event) {
    this.selectedGpId = +event.target.value;

  }

  searchDate() {
    this.disabeldTable = false;
    const url = `${environment.API_URL}/api/GPartnerOrder/GPartnerEarningTransaction?GpartnerId=
    ${this.selectedGpId}&date=${+this.date}`;
    this.gpartnerService.getGPartnerList(url).subscribe
      ((response: ResponseModel<any[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.dbList = response.data;
          this.disabeldTable = true;

        } else (response.status === 400);
        {
          this.emptyList = response.data;
        }
      });

  }
}
