import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';
import { WarehouseService } from '../../../Shared/Services/warehouse.service';

@Component({
  selector: 'ngx-warehouse-delete',
  templateUrl: './warehouse-delete.component.html',
  styleUrls: ['./warehouse-delete.component.scss']
})
export class WarehouseDeleteComponent implements OnInit {

  title: any;
  data: any;
  constructor(private dialogRef: NbDialogRef<any>,
    private warehouseService: WarehouseService, private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  deleteWarehouse() {
    const url = `${environment.API_URL}/api/Warehouse/DeleteWarehouse?Id=${this.data.id}`;
    this.warehouseService.getWarehouseList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }


  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
