import { NgModule } from '@angular/core';
import { AddTicketsComponent } from './add-tickets/add-tickets.component';
import { TicketsComponent } from './tickets/tickets.component';

import { CommonModule } from '@angular/common';
import {
	NbMenuModule, NbInputModule, NbCardModule, NbButtonModule, NbActionsModule,
	NbUserModule, NbCheckboxModule, NbRadioModule, NbDatepickerModule, NbSelectModule,
	NbIconModule, NbAccordionModule, NbDialogModule, NbToastrModule, NbToggleModule, NbTooltipModule, NbTabsetModule
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { RefundComponent } from './refund/refund.component';
import { TicketDialogComponent } from './tickets/dialog/ticket-dialog.component';
import { CareComponent } from './care.component';
import { CareRoutingModule } from './care.routing.module';
import { OrdDetailComponent } from './add-tickets/ord-detail/ord-detail.component';

@NgModule({
	declarations: [AddTicketsComponent,
		 TicketsComponent, TicketDialogComponent, RefundComponent, CareComponent,
		 OrdDetailComponent],
	imports: [
		CareRoutingModule,
		CommonModule,
		NbMenuModule,
		ThemeModule,
		NbInputModule,
		NbCardModule,
		NbButtonModule,
		NbActionsModule,
		NbUserModule,
		NbCheckboxModule,
		NbRadioModule,
		NbDatepickerModule,
		NbSelectModule,
		NbIconModule,
		NbTabsetModule,
 		FormsModule,
		ReactiveFormsModule,
		NbAccordionModule,
		NbDialogModule,
		NbToastrModule,
		NbToggleModule,
		NgxPaginationModule,
		NbTooltipModule,

	],
	entryComponents: [
		TicketDialogComponent,
		RefundComponent,
		OrdDetailComponent
	]
})
export class CareModule { }
