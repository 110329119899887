import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { GrainComponent } from './grain/grain.component';
import { FPmastersComponent } from './fpmasters.component';
import { AreaComponent } from './area/area.component';
import { CityComponent } from './city/city.component';
import { SubGrainComponent } from './sub-grain/sub-grain.component';
import { VendorFormComponent } from './vendor-form/vendor-form.component';
import { EmployeeComponent } from './employee/employee.component';
import { AreaRateBoardComponent } from './area-rate-board/area-rate-board.component';
import { BpRateBoardComponent } from './bp-rate-board/bp-rate-board.component';
import { DeliverySlotComponent } from './delivery-slot/delivery-slot.component';
import { GpWeeklyHolidayComponent } from './gp-weekly-holiday/gp-weekly-holiday.component';
import { GpStockCapacityComponent } from './gp-stock-capacity/gp-stock-capacity.component';
import { SalaryGradComponent } from './salary-grad/salary-grad.component';
import { SettingComponent } from './setting/setting.component';
import { DocComponent } from './doc/doc.component';
import { RatingComponent } from './rating/rating.component';
import { RecipeComponent } from './recipe/recipe.component';
import { ReviewComponent } from './review/review.component';
import { HelpComponent } from './help/help.component';
import { ReportComponent } from './report/report.component';
import { PromocodeComponent } from './promocode/promocode.component';

const routes: Routes = [{
  path: '',
  component: FPmastersComponent,
  children: [

    {
      path: 'doc/:typeid/:userid/:name',
      component: DocComponent,
    },
    {
      path: 'vendor-form',
      component: VendorFormComponent,
    },
    {
      path: 'grain',
      component: GrainComponent,
    },
    {
      path: 'subgrain',
      component: SubGrainComponent,
    },
    {
      path: 'area/:id',
      component: AreaComponent
    },
    {
      path: 'area-rate-board/:areaid/:cityid/:areaNameEng',
      component: AreaRateBoardComponent
    },
    {
      path: 'bp-rate-board/:id/:firstName',
      component: BpRateBoardComponent
    },
    {
      path: 'city',
      component: CityComponent
    },
    {
      path: 'employee',
      component: EmployeeComponent
    },
    {
      path: 'delivery-slot',
      component: DeliverySlotComponent
    },
    {
      path: 'holiday',
      component: GpWeeklyHolidayComponent
    },
    {
      path: 'gp-stock-capacity/:gpid',
      component: GpStockCapacityComponent
    },
    {
      path: 'salary-grad',
      component: SalaryGradComponent
    },
    {
      path: 'setting',
      component: SettingComponent
    },
    {
      path: 'recipe',
      component: RecipeComponent
    },
    {
      path: 'rating',
      component: RatingComponent
    },
    {
      path: 'review',
      component: ReviewComponent
    },
    {
      path: 'help',
      component: HelpComponent
    },
    {
      path: 'promocode',
      component: PromocodeComponent
    },
    {
      path: 'report',
      component: ReportComponent
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FPMasterRoutingModule {
}
