import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IGrain } from '../../models';
import { GpartnerService } from '../../Shared/Services/gpartner.service';


@Component({
  selector: 'ngx-company-stock',
  templateUrl: './company-stock.component.html',
  styleUrls: ['./company-stock.component.scss'],
})
export class CompanyStockComponent implements OnInit {

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  submitted = false;
  companyStock: IGrain[];
  companyTransitStock: any;
  copyCompanyStock: any[];

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  constructor(
    private gpartnerService: GpartnerService,

  ) {
  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
		const role = JSON.parse(rolestr);
		this.pageRole = role.page;
		if (this.pageRole.isWrite === false) {
		  this.saveBtnHideForRole = true;
		}
    this.getCompanyStockList();
    this.getCompanyInTansitSTocklList();
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }




  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.companyStock = this.copyCompanyStock.filter(item => {
        const name = String(item.grainCategory).toLocaleLowerCase();
        const grain = String(item.grain).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr) || grain.includes(searchStr));
      });
    } else {
      this.companyStock = this.copyCompanyStock;
    }
  }
  // get Company  Stock list
  getCompanyStockList() {
    const url = `${environment.API_URL}/api/Stock/Company/GpartrnerStockList?GPId=0`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.companyStock = response.data;
          this.copyCompanyStock = response.data;
          this.config.totalItems = this.companyStock.length;

        }
      });
  }
  // get Company In Transit Stock list
  getCompanyInTansitSTocklList() {
    const url = `${environment.API_URL}/api/Stock/IntransistStockList?GPId=0`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.companyTransitStock = response.data;
        }
      });
  }
}
