import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SubGrainService } from '../../Shared/Services/sub-grain.service';
import { HttpStatusCode, ResponseModel } from '../../Shared/responsemodel';
import { ISubGrain } from '../../models/subGrain.model';
import { IGrain } from '../../models/grain.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { SubgrainDeleteDialogComponent } from './subgrain-delete-dialog/subgrain-delete-dialog.component';
@Component({
  selector: 'ngx-sub-grain',
  templateUrl: './sub-grain.component.html',
  styleUrls: ['./sub-grain.component.scss'],
})
export class SubGrainComponent implements OnInit {

  subGrainForm: FormGroup;
  isDisablebtn = false;
  submitted = false;
  subGrainList: ISubGrain[] = [];
  GranList: IGrain[] = [];
  subGrainId = 0;
  file: File;
  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  @ViewChild('subgrainitem', { static: false }) accordion;
  imgPreview: string;
  copySubGrainList: ISubGrain[] = [];

  constructor(private fb: FormBuilder,
    private subGrainService: SubGrainService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private changeDetectorRef: ChangeDetectorRef) {
    this.subGrainForm = this.fb.group({
      grainId: ['', [Validators.required]],
      english: ['', [Validators.required]],
      hindi: ['', [Validators.required]],
      marathi: ['', [Validators.required]],
      price: ['', [Validators.required]],
      minStock: ['', [Validators.required]],     
      active: [false]
    });
  }

  ngOnInit() {
    this.getGrainList();
    this.getSubGrainList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'Sub-Grain');

    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

  }
  clearForm() {
    this.subGrainForm.reset();
    this.fileInfo = '';
    this.subGrainId = 0;
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // ...
    this.changeDetectorRef.detectChanges();
  }

  get f() {
    return this.subGrainForm.controls;
  }


  fileInfo: string;
  onFileSelect(input: HTMLInputElement): void {

    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.file = input.files[0];
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.imgPreview = (fileReader.result).toString();
    },
      fileReader.readAsDataURL(this.file);
  }

  addSubGrain() {
    this.submitted = true;
    if (this.subGrainForm.valid) {
      this.isDisablebtn = true;
      const formData = new FormData();
      formData.set('Id', this.subGrainId.toString());
      formData.set('GrainId', this.subGrainForm.value.grainId);
      formData.set('Grain', '');
      formData.set('EngName', this.subGrainForm.value.english);
      formData.set('HindiName', this.subGrainForm.value.hindi);
      formData.set('MarathiName', this.subGrainForm.value.marathi);
      formData.set('Price', this.subGrainForm.value.price);
      formData.set('ImagePath', '');
      formData.set('Image', this.file);
      formData.set('Active', this.subGrainForm.value.active);
      formData.set('minStock', this.subGrainForm.value.minStock);
      formData.set('IsShowforRecipe', 'true');

      let apiurl = '';

      if (this.subGrainId === 0) {
        apiurl = `${environment.API_URL}/api/GrainCategory/AddGrainCategory`;
      } else {
        apiurl = `${environment.API_URL}/api/GrainCategory/UpdateGrainCategory`;
      }
      this.subGrainService.modifySubGrain(apiurl, formData).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.subGrainId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.subGrainId = 0;
            this.subGrainForm.reset();
            this.submitted = false;
            this.getSubGrainList();
            this.fileInfo = '';
            this.file = null;
            this.isDisablebtn = false;
            this.accordion.close();
          } else {
            this.showToast('top-right', 'danger', response.statusMessage);
            this.isDisablebtn = false;
          }
        });
    }
  }
  editSubGrain(subgrain: ISubGrain) {
    this.accordion.open();
    this.subGrainId = subgrain.id;
    this.subGrainForm.controls.grainId.setValue(subgrain.grainId);
    this.subGrainForm.controls.english.setValue(subgrain.engName);
    this.subGrainForm.controls.hindi.setValue(subgrain.hindiName);
    this.subGrainForm.controls.marathi.setValue(subgrain.marathiName);
    this.subGrainForm.controls.price.setValue(subgrain.price);
    this.subGrainForm.controls.minStock.setValue(subgrain.minStock);
    this.subGrainForm.controls.active.setValue(subgrain.active);
    this.file = null;
    this.fileInfo = '';

    if (subgrain.imagePath != null || subgrain.imagePath !== undefined || subgrain.imagePath !== '') {

      const imagePathSTR = subgrain.imagePath;

      const arraystr = imagePathSTR.split('/');

      this.fileInfo = arraystr[arraystr.length - 1];
    }
  }

  openDeleteDialog(subgrain: ISubGrain) {
    this.dialogService.open(SubgrainDeleteDialogComponent, {
      context: {
        title: '',
        data: subgrain,
      },
    }).onClose.subscribe(() => {
      this.getSubGrainList();
      this.accordion.close();
    });
  }

  getGrainList() {
    const url = `${environment.API_URL}/api/Grain/GetGrainList?pageSize=0&pageNumber=0`;
    this.subGrainService.getSubGrainList(url).subscribe
      ((response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.GranList = response.data;
        }if (response.status === 204 ) {
          this.GranList = null ;
          this.GranList = [] ;

        }
      });
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }


  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.subGrainList = this.copySubGrainList.filter(item => {
        const name = String(item.engName).toLocaleLowerCase();
        const grain = String(item.grain).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr) || grain.includes(searchStr));
      });
    } else {
      this.subGrainList = this.copySubGrainList;
    }
  }


  getSubGrainList() {
    const url = `${environment.API_URL}/api/GrainCategory/GetGrainCategoryList`;
    this.subGrainService.getSubGrainList(url).subscribe(
      (response: ResponseModel<ISubGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.subGrainList = response.data;
          this.copySubGrainList = response.data;
          this.config.totalItems = this.subGrainList.length;
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}

