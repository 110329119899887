import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { StockInComponent } from './stock-in/stock-in.component';
import { StockoutComponent } from './stockout/stockout.component';
import { CompanyStockComponent } from './company-stock/company-stock.component';
import { GpartnerStockComponent } from './gpartner-stock/gpartner-stock.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { ReadymadeStockinComponent } from './readymade-stockin/readymade-stockin.component';
import { WarehouseStockComponent } from './warehouse/warehouse-stock/warehouse-stock.component';
import { StockComponent } from './stock/stock.component';
import { CodComponent } from './cod/cod.component';

const routes: Routes = [
  {
    path: '',
    component: StockComponent,
    children: [
      {
        path: 'stockin',
        component: StockInComponent
      },
      {
        path: 'stockout',
        component: StockoutComponent
      },
      {
        path: 'companyStock',
        component: CompanyStockComponent
      },
      {
        path: 'gpartnerStock',
        component: GpartnerStockComponent
      },
      {
        path: 'warehouse',
        component: WarehouseComponent
      },
      {
        path: 'rstockin',
        component: ReadymadeStockinComponent
      },
      {
        path: 'warehouseStock',
        component: WarehouseStockComponent
      },
      {
        path: 'cod',
        component: CodComponent
      }

    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StockRoutingModule {
}
