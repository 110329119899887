import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { ICity, IGrain, IRateBoard, IGPStockCapacity } from '../../models';
import { GpartnerService } from '../../Shared/Services/gpartner.service';
import { GrainService } from '../../Shared/Services/grain.service';
import { NbToastrService } from '@nebular/theme';
import { CapacityForGpService } from '../../Shared/Services/capacityForGp.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-gp-stock-capacity',
  templateUrl: './gp-stock-capacity.component.html',
  styleUrls: ['./gp-stock-capacity.component.scss']
})
export class GpStockCapacityComponent implements OnInit {
  @ViewChild('gpStockCapacity', { static: false }) accordion;
  gpList: any;
  GranList: IGrain[];
  StockCapacityList = [];
  selectedgpId = 0;
  selectedGrainId = 0;
  gpGrainList: IGPStockCapacity[] = [];
  disabledBtn = false;

  pageRole: any;
  roleId: number;
  saveBtnHide = true ;


  constructor(private gpartnerService: GpartnerService,
    private toastrService: NbToastrService,
    private capacityForGpService: CapacityForGpService,
    private grainService: GrainService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Business partner stock capacity');


    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }


    this.selectedgpId = +this.activatedroute.snapshot.paramMap.get('gpid');

    if (this.selectedgpId > 0) {
      this.selectedGrainId = 0;
    }

    this.getGPList();
    this.getGrainList();
    this.getGPStockCapacityList();
  }

  // get GP list
  getGPList() {
    const url = `${environment.API_URL}/api/GPartner/GetGPartnerList?pageSize=0&pageNumber=0`;
    this.gpartnerService.getGPartnerList(url).subscribe
      ((response: ResponseModel<ICity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.gpList = response.data;
        }
      });
  }
  // get Grain List
  getGrainList() {
    const url = `${environment.API_URL}/api/Grain/GetGrainList?pageSize=0&pageNumber=0`;
    this.grainService.getGrainList(url).subscribe
      ((response: ResponseModel<IGrain[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.GranList = response.data;
        }
      });
  }

  gpOnChange(event) {
    this.getGPStockCapacityList();
    this.getGPStockCapacity(this.selectedGrainId);
  }

  grainOnChange(event) {
    this.selectedGrainId = event.target.value;
    this.getGPStockCapacity(this.selectedGrainId);
  }

  editCapacityGrain(item) {
    this.StockCapacityList = item.grainCategories;
    this.selectedGrainId = item.grainId;
  }

  // get GP Stock Capacity  list
  getGPStockCapacity(selectedGrainId) {
    const url = `${environment.API_URL}/api/GpstockCapacity/GetGpstockCapacity?GrainId=
    ${selectedGrainId}&&GpId=${this.selectedgpId}`;
    this.gpartnerService.getGPartnerList(url).subscribe(
      (response: ResponseModel<IRateBoard[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.StockCapacityList = response.data['grainCategories'];
        }
      });
  }

  getGPStockCapacityList() {
    const url = `${environment.API_URL}/api/GpstockCapacity/GetGpstockCapacityList?gpId=${this.selectedgpId}`;
    this.gpGrainList = [];
    this.capacityForGpService.getCapacityList(url).subscribe(
      (response: ResponseModel<IGPStockCapacity[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.gpGrainList = response.data;
        }
      });
  }

  // update and add
  saveStockCapacity() {
    this.disabledBtn = true;
    const formData: IGPStockCapacity = {
      gpId: +this.selectedgpId,
      grainId: +this.selectedGrainId,
      grain: '',
      grainCategories: this.StockCapacityList,
    };
    const url = `${environment.API_URL}/api/GpstockCapacity/SaveGpstockCapacity`;
    this.capacityForGpService.modifyCapacityForGp(url, formData).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success', 'Save Successfully');
          this.getGPStockCapacityList();
          this.disabledBtn = false;

        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
