import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { HttpStatusCode, ResponseModel } from '../../../Shared/responsemodel';
import { CityService } from '../../../Shared/Services/city.service';
// import { GpartnerService } from '../../../Shared/Services/gpartner.service';

@Component({
  selector: 'ngx-cod-dialog',
  templateUrl: './cod-dialog.component.html',
  styleUrls: ['./cod-dialog.component.scss']
})
export class CodDialogComponent implements OnInit {
  codForm: FormGroup;

  title: string;

  data: any;
  flag: boolean;

  submitted = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
    private cityService: CityService
  ) {
    this.codForm = this.fb.group({
      status: [],
      tNo: ['']
    });

  }

  ngOnInit() {
    this.codForm.controls.status.setValue(this.flag);
    if (this.data.paymentType === 5) {
      this.codForm.controls.tNo.setValidators([Validators.required]);
      this.codForm.controls.tNo.updateValueAndValidity();
    } else {
      this.codForm.controls.password.setValidators(null);
      this.codForm.controls.password.setErrors(null);
    }

  }

  get f() {
    return this.codForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  updateCodStatus() {
    this.submitted = true;
    if (this.codForm.valid) {
      const data = {
        id: this.data.id,
        isCredited: !(this.data.isCredited),
        transactionNo: this.codForm.value.tNo,
      };
      const url = `${environment.API_URL}​/api/DeliveryBoyOrder/UpdateCodStatus`;
      this.cityService.modifyCity(url, data).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.showToast('top-right', 'success', 'update Successfully');
            this.close();
            this.submitted = false;
          } else {
            this.submitted = false;
          }
        });
    }
  }
  showToast(position, status, message) {
    this.toastrService.show(

      status || 'Success',
      `${message}`,
      { position, status });
  }

  // activeUser(data) {
  //   // this.activeDeactiveBtn = true;
  //   const url = `${environment.API_URL}/api/DeliveryBoyOrder/UpdateCodStatus=${data.id}`;
  //   this.gpService
  //     .getGPartnerList(url)
  //     .subscribe((response: ResponseModel<any>) => {
  //       if (response.status === HttpStatusCode.OK) {
  //         // this.getDeActiveUserList();
  //         // this.getFkUser();
  //         // this.activeDeactiveBtn = false;
  //         // this.showToast('top-right', 'success', 'User Activeted Successfully');
  //       }
  //     });
  // }
}

