import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../responsemodel';

@Injectable({
    providedIn: 'root'
})
export class AreaRateBoardService {

    constructor(private http: HttpClient) { }

    getAreaRateBoardList(url): Observable<any> {
        return this.http.get(url).pipe(map((response: Response) => response as any));
    }

    get(url: string): Observable<any> {
        return this.http.get(url)
            .pipe(map((response: ResponseModel<any>) => {
                return response;
            }));
    }


    modifyAreaRateBoard(url, areaInfo): Observable<any> {
        return this.http.post(url, areaInfo).pipe(map((response: Response) => response as any));
    }
}
