import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-recipe-pop-up',
  templateUrl: './recipe-pop-up.component.html',
  styleUrls: ['./recipe-pop-up.component.scss']
})
export class RecipePopUpComponent implements OnInit {
  title: '';
  data: any ;

  constructor(
    private dialogRef: NbDialogRef<any>,

  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
