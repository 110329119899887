import { Component, OnInit } from '@angular/core';
import { IGrain } from '../../../models/grain.model';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { GrainService } from '../../../Shared/Services/grain.service';
import { ResponseModel, HttpStatusCode } from '../../../Shared/responsemodel';

@Component({
  selector: 'ngx-grain-delete-dialog',
  templateUrl: './grain-delete-dialog.component.html',
  styleUrls: ['./grain-delete-dialog.component.scss']
})
export class GrainDeleteDialogComponent implements OnInit {

  title: string;
  data: IGrain;
  constructor(private dialogRef: NbDialogRef<any>,
    private grainService: GrainService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  deletegrain() {
    const url = `${environment.API_URL}/api/Grain/DeleteGrain?Id=${this.data.id}`;
    this.grainService.getGrainList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.showToast('top-right', 'success');
          this.close();
        }
      }
    );
  }

  showToast(position, status) {
    this.toastrService.show(
      status || 'Success',
      `Deleted Successfully`,
      { position, status });
  }

}
