import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { AreaService } from '../../Shared/Services/area.service';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders } from '../../models/order.model';
import { NbDialogService } from '@nebular/theme';
import { IComplain } from '../../models/complain.model';
import { AddticketDialogComponent } from './add-ticket.dialog/add-ticket.dialog.component';
import { OrdDetailComponent } from './ord-detail/ord-detail.component';

@Component({
  selector: 'ngx-add-tickets',
  templateUrl: './add-tickets.component.html',
  styleUrls: ['./add-tickets.component.scss']
})
export class AddTicketsComponent implements OnInit {

  @ViewChild('addTicktes', { static: false }) accordion;

  userInfo: any;
  userNotFound = false;
  orderList: any[];
  orderdetail: any[];
  noOrderfound = false;
  UserId = 0;
  disabeldTable = false;
  public user = '';

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true ;

  constructor(
    private orderService: OrderService,
    private areaService: AreaService,
    private dialogService: NbDialogService,


  ) {

  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
     this.pageRole = role.page.find(m => m.page === 'Add tickets');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }


  }


  opneOrderDetails(Id) {

    this.dialogService.open(OrdDetailComponent, {
      context: {
        data: Id,
        Title: ''
      },
    });
  }



  addComplainPopUp(order: IComplain) {

    this.dialogService
      .open(AddticketDialogComponent, {
        context: {
          title: '',
          data: order,
        }
      }).onClose.subscribe(() => {
        this.getUserOrderList(this.userInfo.id);
      });

  }


  // search User Name
  findUser() {
    this.userNotFound = false;
    if (this.user != null) {
      const url = `${environment.API_URL}/api/User/username?username=${this.user}`;
      this.areaService.get(url).subscribe
        ((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.userInfo = response.data;
            this.disabeldTable = false;
          } else {
            this.userNotFound = true;
            this.disabeldTable = false;
            this.userInfo = '';
            this.noOrderfound = false;

          }
        });
    }

  }

  // get order list  from customer Id
  getUserOrderList(UserId) {
    this.noOrderfound = false;
    this.disabeldTable = false;
    const url = `${environment.API_URL}/api/Complaint/GetOrderComplaintList?UserId=${UserId}&PageSize=0&PageNo=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.disabeldTable = true;
        } else {
          this.noOrderfound = true;
        }
      });
  }

  // get order details from OrderId
  getOrderDetailList(OrderId) {
    this.noOrderfound = false;
    this.disabeldTable = false;
    const url = `${environment.API_URL}/api/Order/GetOrderDetail?OrderId=${OrderId}`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderdetail = response.data;
          this.disabeldTable = true;
        } else {
          this.noOrderfound = true;
        }
      });
  }

}
