import { Component, OnInit } from '@angular/core';

import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { ComplainService } from '../../Shared/Services/complain.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';

@Component({
  selector: 'ngx-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {
  title: string;
  data: any;

  ComplaintCode = 0;
  orderId = 0;
  productDetails: any[];
  sendBtnDisable = false;
  constructor(
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<any>,
    private complainService: ComplainService,
  ) {
  }

  ngOnInit() {
    this.orderId = this.data.orderId;
    this.ComplaintCode = this.data.complaintCode;
    this.getRefund();

  }

  close() {
    this.dialogRef.close();
  }

  // refund
  getRefund() {
    const url = `${environment.API_URL}/api/Order/GetOrderDetail?OrderId=${this.orderId}`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.productDetails = response.data;
      } else {
        this.productDetails = [];
      }
    });
  }

  public itemlist = [];
  public refundAmmount = 0;

  item(prod) {
    if (this.itemlist.length > 0) {
      const index = this.itemlist.findIndex(m => m === prod.id);
      if (index < 0) {
        this.itemlist.push(prod.id);
        this.refundAmmount = this.refundAmmount + prod.pricewithoutGrinding;
      } else {
        this.itemlist.splice(index, 1);
        this.refundAmmount = this.refundAmmount - prod.pricewithoutGrinding;
      }
    } else {
      this.itemlist.push(prod.id);
      this.refundAmmount = this.refundAmmount + prod.pricewithoutGrinding;
    }
  }

  refundAmount() {
    this.sendBtnDisable = true;
    let query = '';
    for (const item of this.itemlist) {
      query = query + 'OrderDetailsIds=' + item + '&';
    }
    const url = `${environment.API_URL}/api/Order/RefundAmount?${query}ComplaintCode=${this.ComplaintCode}`;
    this.complainService.getComplainList(url).subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.showToast('top-right', 'success', 'Amount Refunded Successfully');
        this.sendBtnDisable = true;
      } else {
        this.showToast('top-right', 'success', 'Please Select Product');
        this.sendBtnDisable = false;
      }

    });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }

}
