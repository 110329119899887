import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { GpartnerComponent } from './gpartner/gpartner.component';
import { DeliveryBoyComponent } from './delivery-boy/delivery-boy.component';
import { AppliedComponent } from './applied/applied.component';
import { FkUserComponent } from './fk-user/fk-user.component';
import { BannerComponent } from './banner/banner.component';


const routes: Routes = [
  {
    path: '',
    component: ProductComponent,
    children: [
      {
        path: 'productform',
        component: ProductFormComponent
      },
      {
        path: 'businessPartner/:firstName',
        component: GpartnerComponent
      },
      {
        path: 'deliveryboy',
        component: DeliveryBoyComponent
      },
      {
        path: 'fk-user',
        component: FkUserComponent
      },
      {
        path: 'applied',
        component: AppliedComponent
      },
      {
        path: 'banner',
        component: BannerComponent
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {
}
