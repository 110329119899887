import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentService } from '../../Shared/Services/payment.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { UpdateDbStatusComponent } from './update-db-status/update-db-status.component';
import { ViewDbDetailsComponent } from './view-db-details/view-db-details.component';

@Component({
	selector: 'ngx-release-db-payment',
	templateUrl: './release-db-payment.component.html',
	styleUrls: ['./release-db-payment.component.scss'],
})
export class ReleaseDbPaymentComponent implements OnInit {
	releasePaymentForm: FormGroup;
	submitted = false;
	@ViewChild('releasebpitem', { static: false }) accordion;
	paymentList = [];
	initialPaymentList = [];
	pendingPaymentList = [];
	donePaymentList = [];
	orderIds = [];
	option = '0';
	tabIndex = 1;

	pageRole: any;
	roleId: number;
	saveBtnHideForRole = true ;

	constructor(
		private fb: FormBuilder,
		private paymentService: PaymentService,
		private dialogService: NbDialogService,
		private toastrService: NbToastrService
	) {
		this.releasePaymentForm = this.fb.group({
			startDate: [''],
			endDate: [''],
		});
	}
	ngOnInit() {
		this.getPendingPaymentList();
		this.getDonePaymentList();
		const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
     this.pageRole = role.page.find(m => m.page === 'Delivery boyPayment');
    if (this.pageRole.isWrite === false) {
      this.saveBtnHideForRole = false;
    }

	}

	get f() {
		return this.releasePaymentForm.controls;
	}

	getTabIndex(event) {
		// console.log(event);
		if (event.tabTitle === 'Order List') {
			this.tabIndex = 1;
		} else if (event.tabTitle === 'Pending') {
			this.tabIndex = 2;
		} else if (event.tabTitle === 'Done') {
			this.tabIndex = 3;
		}
	}

	getDate(dateinfo) {
		const day = dateinfo.getDate().toString();
		const year = dateinfo.getFullYear().toString();
		const month = (dateinfo.getMonth() + 1).toString();
		const dateStr =
			year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
		return dateStr;
	}

	getList() {
		this.submitted = true;
		if (this.option === '0') {
			this.releasePaymentForm.controls.startDate.setValidators([
				Validators.required,
			]);
			this.releasePaymentForm.controls.startDate.updateValueAndValidity();
			this.releasePaymentForm.controls.endDate.setValidators([
				Validators.required,
			]);
			this.releasePaymentForm.controls.endDate.updateValueAndValidity();
		} else {
			this.releasePaymentForm.controls.startDate.setValidators(null);
			this.releasePaymentForm.controls.startDate.setErrors(null);
			this.releasePaymentForm.controls.endDate.setValidators(null);
			this.releasePaymentForm.controls.endDate.setErrors(null);
		}
		if (this.releasePaymentForm.valid) {
			const data = {
				date: +this.option,
				startDate: this.releasePaymentForm.value.startDate
					? this.getDate(this.releasePaymentForm.value.startDate)
					: null,
					endDate: this.releasePaymentForm.value.endDate
					? this.getDate(this.releasePaymentForm.value.endDate)
					: null,
				pageNumber: 0,
				pageSize: 0,
			};

			const apiurl = `${environment.API_URL}/api/DeliveryBoyOrder/DeliveryBoyPayment`;
			this.paymentService
				.releasePayment(apiurl, data)
				.subscribe((response: ResponseModel<any>) => {
					if (response.status === HttpStatusCode.OK) {
						this.paymentList = [];
						(response.data as Array<any>).forEach((element, index) => {
							element['checked'] = false;
							this.paymentList.push(element);
						});
					} else if (response.status === HttpStatusCode.NO_CONTENT) {
						this.paymentList = [];
					} else {
						// this.showToast('top-right', 'danger', response.statusMessage);
					}
				});
		}
	}

	selecAll(event) {
		this.orderIds = [];
		if (event.target.checked) {
			for (let i = 0; i < this.paymentList.length; i++) {
				this.paymentList[i].checked = true;
				this.orderIds.push(this.paymentList[i].orderId);
			}
		} else {
			for (let i = 0; i < this.paymentList.length; i++) {
				this.paymentList[i].checked = false;
			}
			this.orderIds = [];
		}
	}

	toggle(event, payment) {
		if (event.target.checked && !this.orderIds.includes(payment.orderId)) {
			this.orderIds.push(payment.orderId);
		} else {
			for (let i = 0; i < this.orderIds.length; i++) {
				if (this.orderIds[i] === payment.orderId) {
					this.orderIds.splice(i, 1);
					break;
				}
			}
		}
	}

	releasePayment() {
		const data = {
			orderIds: this.orderIds,
		};
		const apiurl = `${environment.API_URL}/api/DeliveryBoyOrder/ReleasePayment`;
		this.paymentService
			.releasePayment(apiurl, data)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					window.open(response.data, '_blank');
				} else {
					// this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}

	showToast(position, status, message) {
		this.toastrService.show(status || 'Success', `${message}`, {
			position,
			status,
		});
	}

	getPendingPaymentList() {
		const apiurl = `${environment.API_URL}/api/DeliveryBoyOrder/GetReleasePayment?status=3`;
		this.paymentService
			.getPaymentList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.pendingPaymentList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.pendingPaymentList = [];
				} else {
					// this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}

	getDonePaymentList() {
		const apiurl = `${environment.API_URL}/api/DeliveryBoyOrder/GetReleasePayment?status=1`;
		this.paymentService
			.getPaymentList(apiurl)
			.subscribe((response: ResponseModel<any>) => {
				if (response.status === HttpStatusCode.OK) {
					this.donePaymentList = response.data;
				} else if (response.status === HttpStatusCode.NO_CONTENT) {
					this.donePaymentList = [];
				} else {
					// this.showToast('top-right', 'danger', response.statusMessage);
				}
			});
	}

	openUpdateStatusDialog(order) {
		this.dialogService
			.open(UpdateDbStatusComponent, {
				context: {
					title: '',
					data: order,
				},
			})
			.onClose.subscribe(() => {
				this.getPendingPaymentList();
				this.getDonePaymentList();
			});
	}

	openViewDetailDialog(order) {
		this.dialogService
			.open(ViewDbDetailsComponent, {
				context: {
					title: '',
					data: order,
				},
			})
			.onClose.subscribe(() => {});
	}
}
