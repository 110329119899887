import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OrderService } from '../../Shared/Services/order.service';
import { IOrders, IOrderDetails } from '../../models/order.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { AssingOrderDbComponent } from '../pass-order-db/assing-order-db/assing-order-db.component';
import { StatusUpdateComponent } from '../../status-update/status-update.component';

@Component({
  selector: 'ngx-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {

  public orderList = [];
  copyOrderList = [];
  OrderCompletedbyBusinessPartner = false;
  pageRole: any;

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private dialogService: NbDialogService,
    private orderService: OrderService
  ) {

  }

  ngOnInit() {
    this.getCompleteOrderList();
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    this.pageRole = role.page.find(m => m.page === 'OrderCompletedbyBusinessPartner');
    if ((this.pageRole.isWrite)) {
      this.OrderCompletedbyBusinessPartner = true;
    }
  }
  // open pop for order assing to DB
  openPopUpForAssing(order: IOrderDetails) {
    this.dialogService.open(AssingOrderDbComponent, {
      context: {
        title: '',
        data: order,
      },

    }).onClose.subscribe(() => {
      this.getCompleteOrderList();
    });

  }
  open(order: IOrderDetails) {
    this.dialogService.open(OrderDialogComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 5,
        }
      },
    });
  }

  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }



  // filter(str: string) {
  //   if (str.trim() !== '') {
  //     this.orderList = this.copyOrderList.filter(item => {
  //       const order = String(item.orderDetails.orderId).toLocaleLowerCase();
  //       const fName = String(item.userInfo.firstName).toLocaleLowerCase();
  //       const lName = String(item.userInfo.lastName).toLocaleLowerCase();
  //       const area = String(item.userInfo.area).toLocaleLowerCase();
  //       const searchStr = String(str).toLowerCase();
  //       return (order.includes(searchStr)) ||
  //         (fName.includes(searchStr)) || (lName.includes(searchStr)) || (area.includes(searchStr));
  //     });

  //   } else {
  //     this.orderList = this.copyOrderList;
  //   }
  // }

  filter(str: string) {
    str = str.trim();
    if (str !== ' ')  {
    const url = `${environment.API_URL}/api/Order/GetOrderSerach?Serchstr=${str}&isdelivery=true&PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          // this.config.totalItems = this.orderList.length;
        } if (response.status === 204) {
          this.getCompleteOrderList();
        }
      });
    }
  }
  // get order list
  getCompleteOrderList() {
    const url = `${environment.API_URL}/api/Order/GetOrdersFromAdmin?status=4&PageSize=0&PageNumber=0`;
    this.orderService.getOrderList(url).subscribe
      ((response: ResponseModel<IOrders[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.orderList = response.data;
          this.copyOrderList = response.data;
          this.config.totalItems = this.orderList.length;
        } if (response.status === 204) {
          this.orderList = [];
        }
      });
  }
  statusUpdate(order: IOrderDetails) {
    this.dialogService.open(StatusUpdateComponent, {
      context: {
        title: '',
        data: {
          order: order,
          status: 5,
        }
      },

    }).onClose.subscribe(() => {
      this.getCompleteOrderList();
    });
  }
}
