import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from '../../Shared/Services/employee.service';
import { environment } from '../../../environments/environment';
import { ResponseModel, HttpStatusCode } from '../../Shared/responsemodel';
import { IEmployee, ISalaryGrad } from '../../models/index';
import { NbToastrService, NbDialogService, NbDateService } from '@nebular/theme';
import { EmployeeDeleteDialogComponent } from './employee-delete-dialog/employee-delete-dialog.component';
import { DocumentComponent } from './document/document.component';
import { SalaryGradService } from '../../Shared/Services/salaryGrad.service';
@Component({
  selector: 'ngx-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  employeeForm: FormGroup;
  employeeId = 0;

  @ViewChild('employeeitem', { static: false }) accordion;

  submitted = false;
  salarysList: any[];
  pageRole: any;
  roleId: number;
  saveBtnHide = true ;


  pageSize = 50;
  pageNumber = 1;

  min: Date;
  max: Date;

  isdisable = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  empList: IEmployee[] = [];
  copyEmpList: IEmployee[] = [];
  Role = [
    { id: 1, title: 'Admin' },
    { id: 2, title: 'Employee' },
    { id: 3, title: 'Customer Care Manager' },
    { id: 4, title: 'Customer Care' },
    { id: 5, title: 'Accountant' },
  ];

  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private fb: FormBuilder,
    private salaryGradService: SalaryGradService,
    private employeeService: EmployeeService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>
  ) {

    this.min = this.dateService.addYear(this.dateService.today(), -150);
    this.max = this.dateService.today();

    this.employeeForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobile1: ['', [Validators.required]],
      mobile2: [''],
      address: ['', [Validators.required]],
      role: ['', [Validators.required]],
      joinDate: ['', [Validators.required]],
      salaryId: ['', [Validators.required]],
      active: [true],
    });

  }

  ngOnInit() {
    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);
    // this.pageRole = role.page;
		this.pageRole = role.page.find(m => m.page === 'Employee');


    if (this.pageRole.isWrite === false) {
      this.saveBtnHide = false;
    }

    this.getEmployeeList();
    this.getSalaryGradList();
  }
  pageChange(pageNo) {
    this.config.currentPage = pageNo;
  }
  clearForm() {
    this.employeeForm.reset();
    this.submitted = false;
    this.employeeId = 0;
  }
  open(subgrain: IEmployee) {
    this.dialogService.open(DocumentComponent, {
      context: {
        title1: '',
        data: subgrain,
      },
    });
  }

  get f() {
    return this.employeeForm.controls;
  }

  openDeleteDialog(subgrain: IEmployee) {
    this.dialogService.open(EmployeeDeleteDialogComponent, {
      context: {
        title: '',
        data: subgrain,
      },
    }).onClose.subscribe(() => {
      this.getEmployeeList();
    });
  }

  getDate(dateinfo) {
    const day = dateinfo.getDate().toString();
    const year = dateinfo.getFullYear().toString();
    const month = (dateinfo.getMonth() + 1).toString();
    const dateStr = year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    return dateStr;
  }

  saveEmployee() {
    this.submitted = true;

    if (this.employeeForm.valid) {

      const data: IEmployee = {
        id: this.employeeId,
        salaryGrade: this.employeeForm.value.salaryGrade,
        salaryId: +this.employeeForm.value.salaryId,
        firstName: this.employeeForm.value.firstName,
        lastName: this.employeeForm.value.lastName,
        userName: this.employeeForm.value.username,
        email: this.employeeForm.value.email,
        address: this.employeeForm.value.address,
        isActive: this.employeeForm.value.active,
        mobileNo: this.employeeForm.value.mobile1,
        roleId: +this.employeeForm.value.role,
        password: '',
        registrationDate: this.getDate(this.employeeForm.value.joinDate),
      };

      this.isdisable = true;
      let apiurl = '';
      if (this.employeeId === 0) {
        apiurl = `${environment.API_URL}/api/AdminUser/AdminUserRegister`;
      } else {
        apiurl = `${environment.API_URL}/api/AdminUser/UpdateAdminUser`;
      }
      this.employeeService.modifyEmployee(apiurl, data).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.getEmployeeList();
            if (this.employeeId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.employeeId = 0;
            this.submitted = false;
            this.employeeForm.reset();
            this.isdisable = false;
            this.accordion.close();
          } else {
            this.showToast('top-right', 'danger', 'Mobile No.1 Is All Ready Exit');
            this.isdisable = false;

          }
          // (error) => {
          //   this.showToast('top-right', 'danger', error.message);
          // }
        });
    }
  }


  editEmployee(employeeInfo: IEmployee) {
    this.accordion.open();
    this.employeeId = employeeInfo.id;
    this.employeeForm.controls.firstName.setValue(employeeInfo.firstName);
    this.employeeForm.controls.lastName.setValue(employeeInfo.lastName);
    this.employeeForm.controls.address.setValue(employeeInfo.address);
    this.employeeForm.controls.active.setValue(employeeInfo.isActive);
    this.employeeForm.controls.mobile1.setValue(employeeInfo.mobileNo);
    this.employeeForm.controls.mobile2.setValue(employeeInfo.mobileNo);
    this.employeeForm.controls.role.setValue(employeeInfo.roleId);
    this.employeeForm.controls.joinDate.setValue(new Date(employeeInfo.registrationDate));
    this.employeeForm.controls.salaryId.setValue(employeeInfo.salaryId);

  }



  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.empList = this.copyEmpList.filter(item => {
        const fName = String(item.firstName).toLocaleLowerCase();
        const lName = String(item.lastName).toLocaleLowerCase();

        const searchStr = String(str).toLowerCase();
        return (fName.includes(searchStr) || lName.includes(searchStr));
      });
    } else {
      this.empList = this.copyEmpList;
    }
  }
  getEmployeeList() {
    const url = `${environment.API_URL}/api/AdminUser/AdminAllUserList`;
    this.employeeService.getEmployeeList(url).subscribe(
      (response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.empList = response.data;
          this.copyEmpList = response.data;
          this.config.totalItems = this.empList.length;
        }
      });
  }


  // get salary grad  list
  getSalaryGradList() {
    const url = `${environment.API_URL}/api/Grade/GetGradeList`;
    this.salaryGradService.getSalaryList(url).subscribe
      ((response: ResponseModel<ISalaryGrad[]>) => {
        if (response.status === HttpStatusCode.OK) {
          this.salarysList = response.data;
        }
      });
  }

  showToast(position, status, message) {
    this.toastrService.show(
      status || 'Success',
      `${message}`,
      { position, status });
  }
}
